import FooterSection from "../common/Footer";
import Header from "../common/Header";
import BoxesSection from "./BoxesSection";
// import DocumentSection from './DocumentSection';
import HowtoSection from "./HowtoSection";
// import IcbExchangeSection from './IcbExchangeSection';
import IntroductionSection from "./IntroductionSection";
import MultichainSection from "./MultichainSection";
import PromotionSection from "./PromotionSection";
import SolutionSection from "./SolutionSection";

const Main = () => {
  return (
    <>
      <Header />
      <IntroductionSection />
      <BoxesSection />
      <SolutionSection />
      <HowtoSection />
      {/* <IcbExchangeSection /> */}
      {/* <DocumentSection /> */}
      <MultichainSection />
      <PromotionSection />
      <FooterSection />
    </>
  );
};

export default Main;
