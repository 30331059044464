import styles from './Fuseiboushi.module.scss'
import FooterSection from '../common/Footer'
import Header from '../common/Header'
import PagesHeader from '../common/PagesHeader'

const Fuseiboushi = () => {
  return (
    <>
      <Header />
      <PagesHeader title={"不正利用防止に関するご協力のお願い"} />
      <div className={styles.wrapper}>
        <p>平素は当社および当社事業に深いご理解とご協力を賜り、誠にありがとうございます。</p>
        <p>ユーザーのみなさまのお力添えにより、「JPYC」は、2021年1月27日のリリース以来、日本初のERC20日本円連動ステーブルコインとして、多くの方にご好評いただいております！</p>
        <p>一方で事業規模の拡大に伴い、当社および「JPYC」をめぐる環境は日々変動しております。</p>
        <p>特にアンチ・マネーロンダリングおよびテロ資金供与対策の分野では情勢変動や法改正等の影響もあり、その傾向が顕著です。</p>
        <p>現在、我が国においては、暗号資産交換業などで厳格なKYC（Know Your Customer）が求められております。しかし、「JPYC」は前払式支払手段であるため、法律上、そのようなKYCは求められておりません。</p>
        <p>しかしながら、当社では、より多くの方に「JPYC」を安全・安心にご利用いただき、かつ、社会インフラを担う「JPYC」提供事業者としての社会的責任を果たすために、アンチ・マネーロンダリングおよびテロ資金供与対策を自主的に行なっております。</p>
        <p>不正取引防止のため、高額なお取引など取引リスクに応じて、eKYC等を実施させていただきます。</p>
        <div className={styles.abstruct}>
          <div className={styles.abstructTitle}>
            eKYCの概要
          </div>
          <ul>
            <li>200万円以上のお取引については、eKYCを実施させていただきます。</li>
            <li>200万円未満のお取引につきましても、リスクに応じた抽出基準に該当した場合、eKYCを実施させていただく場合があります。</li>
            <li>eKYC実施済みのウォレットアドレスにつきましては、原則として、取引ごとに改めてのeKYCを必要とせずに、お取引いただけます。（ただし、eKYC実施から長期間経過した場合など、再度のeKYCをお願いする場合があります。）</li>
            <li>取引によっては、eKYCのほか、お取引目的等の確認をさせていただく場合がございます。</li>
            <li>具体的な実施基準などの詳細につきましては、セキュリティの観点から、公表を差し控えさせていただきます。</li>
          </ul>
        </div>
        <p>ユーザーのみなさまには、大変ご不便をお掛け致しますが、ご理解ご協力の程、何卒よろしくお願い申し上げます。</p>
        <p>引き続き、「JPYC」が日本を代表する前払式支払手段扱いの日本円連動ステーブルコインとなることで業界をリードできるよう、行政や金融機関をはじめとした関係各所とのコミュニケーションを密にとり、不正取引防止に取り組んで参ります。</p>
        <p>2022年7月<br/>JPYC株式会社</p>
      </div>
      <FooterSection />
    </>
  )
}

export default Fuseiboushi
