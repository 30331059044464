import React from "react";
import styles from "./Tokushoho.module.scss";
import clsx from "clsx";
import { Link, useLocation, useParams } from "react-router-dom";
import Gift from "./Gift";
import Giftee from "./Giftee";
import MatsuyaGinza from "./MatsuyaGinza";
import Shop from "./Shop";
import Shopping from "./Shopping";
import PagesHeader from "../common/PagesHeader";
import Header from "../common/Header";
import FooterSection from "../common/Footer";

type Target = {
  target: string;
};

const Tokushoho = () => {
  const { target } = useParams<Target>();
  const { hash } = useLocation();
  const [openHash, setOpenHash] = React.useState(
    target ? "#" + target : hash ? hash : "#shop"
  );

  const tabHead = [
    {
      link: "#shop",
      name: "JPYC販売所",
    },
    {
      link: "#shopping",
      name: "JPYCショッピング",
    },
    {
      link: "#gift",
      name: "JPYCギフト交換",
    },
    {
      link: "#giftee",
      name: "giftee Box交換",
    },
    {
      link: "#matsuya_ginza",
      name: "松屋銀座",
    },
  ];
  return (
    <>
      <Header />
      <PagesHeader title={"特定商取引法に基づく表示"} />
      <div className={styles.tokushohoWrapper}>
        <ul className={styles.tokushohoUl}>
          {tabHead.map((item) => {
            return (
              <li className={styles.tokushohoLi} key={item.link}>
                <Link
                  to={"/tokushoho" + item.link}
                  onClick={() => setOpenHash(item.link)}
                  style={{ textDecoration: "none" }}
                >
                  <p
                    className={clsx([
                      styles.notSelected,
                      openHash === "" &&
                        item.link === "#shop" &&
                        styles.selected,
                      openHash === item.link && styles.selected,
                    ])}
                  >
                    {item.name}
                  </p>
                </Link>
              </li>
            );
          })}
        </ul>
        {openHash === "#shop" && <Shop />}
        {openHash === "#shopping" && <Shopping />}
        {openHash === "#gift" && <Gift />}
        {openHash === "#giftee" && <Giftee />}
        {openHash === "#matsuya_ginza" && <MatsuyaGinza />}
      </div>
      <FooterSection />
    </>
  );
};

export default Tokushoho;
