import React from 'react'
import styles from './Props.module.scss'

const UnderLine = () => {
  return (
    <div className={styles.underLine}></div>
  )
}

export default UnderLine
