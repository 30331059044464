/* eslint-disable @typescript-eslint/naming-convention */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationJA from "../locales/ja.json";
import translationEN from "../locales/en.json";

declare module "react-i18next" {
  interface CustomTypeOptions {
    defaultNS: "ns1";
    resources: {
      tlJA: typeof translationJA;
      tlEN: typeof translationEN;
    };
  }
}

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: () => {
      const lang = window.navigator.language;
      if (lang === "ja" || lang === "ja-JP") {
        return ["ja"];
      } else {
        return ["en"];
      }
    },
    resources: {
      en: {
        translation: translationEN,
      },
      ja: {
        translation: translationJA,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      // wait: true,
      useSuspense: false,
    },
  });

export default i18n;
