import React from 'react'
import styles from './PagesHeader.module.scss'

const PagesHeader = ({ title }:any) => {
  return (
    <section className={styles.pagesHeader}>
      <h1 className={styles.title}>{title}</h1>
    </section>
  )
}

export default PagesHeader
