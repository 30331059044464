import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import PagesHeader from '../common/PagesHeader'
import UnderLine from '../common/Props'
import styles from './PrivacyPolicy.module.scss'


const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap" rel="stylesheet"></link>
      <PagesHeader title={"プライバシーポリシー"} />
      <div className={styles.ppWrapper}>
        <div className={styles.ppBox}>
          
          <p>JPYC株式会社（以下、「当社」とします。）は、個人情報（個人情報の保護に関する法律（以下、「個人情報保護法」とします。）第２条１項にて定義された「個人情報」を意味します。以下、同じ。）の重要性に鑑み、当社が提供するサービス（以下、「当社サービス」とします。）において取得した個人情報の取り扱いについて、下記の通りプライバシーポリシー（以下、「本ポリシー」とします。）を定めます。なお、本ポリシーに特段の定めがない限り、本ポリシーにおける用語の定義は個人情報保護法の定めに従います。</p>
            <br />
          <p>〈事業者情報〉</p>
            <p>法人名：JPYC株式会社</p>
            <p>住所：〒100-0004 東京都千代田区大手町一丁目6番1号 大手町ビル4階 FINOLAB内</p>
            <p>代表者：岡部典孝</p>
        </div>
        <div className={styles.ppBox}>
        <h2 className={styles.clauseTitle}>〈個人情報保護指針〉</h2>
        <ol className={styles.indentedList}>
            <li>
              当社は、『社会のジレンマを突破する』というミッションのもと、当社サービスを通じて、資本の流動性向上を実現させ、誰もがイノベーションを起こしやすい土壌を創り、皆さんのイノベーション促進のための企業を目指します。
            </li>
            <li>
              当社は、この目的達成のために、当社サービスの利用等を通じて収集したお客様の個人情報を適法かつ適切に利用することが重要であると考え、ここに役員及び従業員一同並びに当社が遵守すべき行動規範として個人情報保護指針を定めることで、その遵守を徹底します。
            </li>
            <li>
              ・当社は、当社サービスの利用等を通じて取り扱う個人情報について、個人情報保護法をはじめとした各種法令・個人情報保護委員会・金融庁が定めるガイドライン等及び本ポリシーを遵守し、適法かつ適切に取り扱います。
            </li>
            <li>
              ・当社は、個人情報への不正アクセス、紛失、破壊、改ざん、漏洩、滅失等のリスクに対して合理的な管理措置等を講じます。
            </li>
            <li>
              ・当社は、個人情報を収集する場合、本ポリシーに定めるように、事前に収集方法等をできる限り明確・明示し、適法かつ適切な態様によって収集を行います。また、収集した個人情報の利用にあたっては、利用目的の範囲内でのみ利用し、目的外利用を行わないための適切な措置を講じます。 
            </li>
            <li>
              ・当社は、収集した個人情報をお客様の事前の同意なく、第三者に提供することはありません。
            </li>
            <li>
              ・当社は、収集した個人情報の取扱いの全部又は一部を委託する場合は、委託先を当社内部規程・基準等に従って適切に選定します。また、当社は、収集した個人情報の取扱いの全部又は一部を委託する場合に委託先に安全管理措置を遵守させるために必要な契約を締結する等の必要な措置を講じます。
            </li>
            <li>
              ・当社は、個人情報及び個人情報の取扱いに関するお問い合わせ及び苦情に対して適切に対応いたします。
            </li>
            <li>
              ・当社は、収集した個人情報に関して削除・利用停止・訂正・開示・利用目的の通知等の要請に対して、各種法令の定めに従い、遅滞なくこれに対応いたします。
            </li>
            <li>
              ・当社は、個人情報の取り扱いや本ポリシー等を適宜見直し、個人情報保護の目的達成のために、継続的に改善してまいります。
            </li>
          
        </ol>
        </div>
        </div>
        
        <UnderLine />
        <div className={styles.ppBox}>
        <h2 className={styles.clauseTitle}>〈個人情報の取り扱い〉</h2>
   
          <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第１条（基本方針）</h2>
            <li>
              １ 社は、個人情報保護法をはじめとした各種法令・個人情報保護委員会が定めるガイドライン等及び本ポリシーを遵守するとともに、個⼈情報を適法かつ適切に取り扱います。
            </li>
            <li>
              ２ 当社は、保有する個⼈情報に関して、合理的な安全措置をとることにより、不正アクセス、紛失、破壊、改ざん、漏洩、滅失等を防⽌するために適切な措置をとるよう努めます。
            </li>
            <li>
              ３ 当社は、役員及び従業員一同が一体となって各種法令、個人情報保護委員会・金融庁が定めるガイドライン等及び本ポリシーを遵守し、もって個人情報の保護及び個人の権利利益の保護に万全を尽くしてまいります。
            </li>
            </ol>
     
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第２条（個人情報の利用目的）</h2>
          <ul className={styles.ppSection}>
            <li>
              １  当社は、当社サービスにて取得した個人情報を以下の目的をもって利用します。
            </li>
            <ol className={styles.indentedList}>
              <li>(1)当社サービスに関する各種事項の連絡や情報提供を行うため</li>
              <li>(2)当社サービスを提供するため</li>
              <li>(3)お客様のお申込みにかかる当社サービス間の情報連携のため</li>
              <li>(4)電子メール配信サービスのお申し込みの確認やメールを配信するため</li>
              <li>(5)当社サービスに関するご購入の確認やお届けをするため</li>
              <li>(6)当社サービスに関するご請求、お支払いとその確認をするため</li>
              <li>(7)ご協力いただいた調査等に対する謝礼等をお送りするため</li>
              <li>(8)ご応募いただいた懸賞等に対する景品等をお送りするため</li>
              <li>(9)当社サービスの利用状況等を調査・分析するため</li>
              <li>(10)当社サービスの内容をよりご満足いただけるよう改良・改善し、または新サービスを開発するため</li>
              <li>(11)当社サービスの内容をお客様にあわせてカスタマイズするため</li>
              <li>(12)当社サービスに関する満足度を調査するため</li>
              <li>(13)取得情報を統計的に処理した情報を集約し調査結果として公表するため</li>
              <li>(14)当社サービス、新サービスに関する研究開発をするため</li>
              <li>(15)当社サービス、新サービスその他の各種サービスのご案内やお知らせをお届けする等、マーケティングで利用するため</li>
              <li>(16)調査のご協力依頼や各種イベントへのご参加をお願いしたり、その結果等をご報告するため</li>
              <li>(17)当社サービス運営上のトラブルの解決のため</li>
              <li>(18)当社サービスに関する不正利用防止や安全性の確保のため</li>
            </ol>
            <li>
              ２  当社が、お客様の個人情報を取得する場合、その取得に先立って、ご本人に対し利用目的を明示するものとします。当社は、お客様の個人情報を利用目的の達成に必要な範囲で利用し、それ以外の目的には利用いたしません
            </li>
            <li>
              ３  第１項１３号により統計処理されたデータが第三者に対して公開される場合がございますが、個々の個人情報が統計分析の結果に含まれることはありません。
            </li>
          </ul>
        </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第３条（個人情報の収集）</h2>
          

            <li>
              １ 当社は、お客様から提供される方法をもって以下の情報を収集いたします。
            </li>
            <ol className={styles.indentedList}>
              <li>(1)氏名（フリガナを含む）、住所、電話番号（携帯電話番号・ファクシミリ番号を含む）、メールアドレス、銀行口座情報（金融機関名、支店名、預金種別、口座番号、口座名義等）、会社名・団体名、勤務先所在地、その他連絡先に関する情報、ニックネーム、生年月日、性別、ウォレットアドレスなど、お客様から当社に提供される一切の情報</li>
            </ol>
             <li>
              ２  当社は、お客様が当社サービスを利用することによって以下の情報を収集いたします。
            </li>
            <ol className={styles.indentedList}>
              <li>(1)氏名（フリガナを含む）、住所、電話番号（携帯電話番号・ファクシミリ番号を含む）、メールアドレス、銀行口座情報（金融機関名、支店名、預金種別、口座番号、口座名義等）、会社名・団体名、勤務先所在地、その他連絡先に関する情報、ニックネーム、生年月日、性別、ウォレットアドレスなど、お客様から当社に提供される一切の情報</li>
              <li>(2)当社からのメールマガジンなどの購読状況に関する情報</li>
              <li>(3)電話や電子メールその他の手段により、当社に質問した、アンケートやキャンペーンに参加した、掲示板を利用した、又は当社サービスを評価するなどを行った場合の、その発言あるいは記載内容に関する情報</li>
            </ol>
             <li>
              ３ 当社は、お客様がアクセスしたことを契機として機械的に取得する方法をもって以下の情報を収集いたします。
            </li>
            <ol className={styles.indentedList}>
              <li>(1)お客様のコンピュータがインターネットに接続するときに使用されるIPアドレス、携帯端末の機体識別に関する情報</li>
              <li>(2)当社の運営するウェブサイトにアクセスしたことを契機として取得された、お使いのブラウザの種類・バージョン、オペレーティングシステム、プラットフォームなどのほか、お客様の閲覧されたページ(URL)、閲覧した日時、表示又は検索された商品などに関する情報</li>
              <li>(3)第１号及び第２号に定めるもののほか、クッキー(cookie)やウェブビーコン(web beacon)の技術を使用して取得したアクセス情報など、お客様が当社サービスを利用されるごとに、自動的に収集・保管される情報</li>
            </ol>
          
        </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第４条（利用目的の変更）</h2>
            <li>
              １ 当社は、本ポリシーの変更が変更前と関連性を有すると合理的に認められる場合、本ポリシーの利用目的を変更する場合があります。
            </li>
            <li>
              ２ 本ポリシーの利用目的の変更が、法令上、お客様への公表やお客様の同意を要する内容の変更を伴う場合、当社は、適用法令の定めにしたがって本ポリシーの利用目的の変更を行うものとします。
            </li>
        </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第５条（当社が設定したリンク先等での個人情報の取り扱い）</h2>
            <li>
              １ 当社サービス上等において設定された外部リンクによって外部サイトを利用する場合において、本ポリシーは当該外部サイト上で適用されず、当該外部サイトのプライバシーポリシーが適用されることとなります。
            </li>
            <li>
              ２ 前項に定める場合において、お客様と当該外部サイトの間で発生した一切の事項につきましては、お客様の当該外部サイトにアクセスする動機となったのが当社が設定した外部リンクに起因するもの等であったとしても、当社は一切の責任を負いかねます。
            </li>
        </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第６条（第三者提供）</h2>


            <li>
              １ 当社は、次に掲げる場合は個人情報保護法に基づき、お客様ご本人の同意を得ることなく第三者に個人情報を提供いたします。ただし、個人情報保護法その他法令で認めている場合はこれらに限りません。
            </li>
            <ol className={styles.indentedList}>
              <li>(1)当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
              <li>(2)当社サービスを提供するために必要な業務の全部または一部を第三者に委託する場合（この場合、当社が委託先へ提供する個人情報は、委託先が業務を遂行するために必要な個人情報に限るものとし、委託先との間で個人情報の取扱いに関する契約を締結し、適切な監督を行います。）</li>
              <li>(3)合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
              <li>(4)提携先または情報収集モジュール提供者へ個人情報が提供される場合</li>
              <li>(5)国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合</li>
            </ol>
            <li>
              ２ 本ポリシーに同意することをもって、お客様はその個人情報（本項においては、氏名（フリガナを含む。）、住所、電話番号（携帯電話番号・ファクシミリ番号を含む。）、メールアドレス、会社名・団体名、勤務先所在地、その他連絡先に関する情報、ニックネーム、生年月日、性別、ウォレットアドレス等を含むものとします。）を、以下に定める第三者に対し、書面、電話、FAXもしくは電磁的記録等を送信する方法で提供することに同意したものとみなします。
            </li>
            <ol className={styles.indentedList}>
              <li>(1)代理購入における発送元事業者</li>
              <li>(2)当社サービス提供に付随する本人確認機関</li>
            </ol>
          </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第７条（安全管理措置）</h2>

            <li>
              当社は、取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について、個人情報保護規程等を策定しております。また、個人情報の不正アクセス、紛失、破壊、改ざん、漏洩、滅失等を未然に防止し、かつ、個人情報を利用目的の範囲内で最新・完全・正確な内容に保つように努めるため、個人情報の管理・処理に従事するスタッフと秘密保持契約を締結し、SSL暗号化通信を行う等の現時点での技術水準に合った適切かつ必要な安全管理措置を講じ、適宜見直しや是正等を行ってまいります。具体的には、以下に掲げる措置を講じております。なお、当社の情報セキュリティ方針に関しましては<a href="https://jpyc.co.jp/security">情報セキュリティ基本方針</a>をご参照ください。
            </li>
             <li>〈組織的安全管理措置〉</li>
             <ol className={styles.indentedList}>
                <li>(1)個人情報管理責任者の設置</li>
                <li>(2)個人情報保護規程等社内規程の設置・整備</li>
                <li>(3)監査部門による個人情報取扱状況の点検及び監査体制の整備・実施</li>
                <li>(4)漏洩等事案に対する体制の整備</li>
              </ol>
              <li>〈人的安全管理措置〉</li>
              <ol className={styles.indentedList}>
                <li>(1)従業者（金融分野における個人情報保護に関するガイドラインの定義による。以下、同じ。）への研修等を通じた安全管理措置の周知徹底・教育及び訓練</li>
                <li>(2)従業者による個人情報管理手続の遵守状況確認</li>
                <li>(3)従業者との就業規則等社内規程を通じた個人情報の非開示契約等の締結</li>
                <li>(4)従業者の役割・責任の明確化</li>
                </ol>
             <li>〈物理的安全管理措置〉</li>
             <ol className={styles.indentedList}>
                <li>(1)個人情報の取扱区域等の管理（電磁的記録である場合はアクセス権限の設定等）</li>
                <li>(2)機器及び電子媒体等の盗難等の防止</li>
                <li>(3)電子媒体を持ち運ぶ場合の漏洩等の防止</li>
              </ol>
             <li>〈技術的安全管理措置〉</li>
             <ol className={styles.indentedList}>
                <li>(1)個人情報の利用者の識別及び認証</li>
                <li>(2)個人情報の管理区分の設定及びアクセス制限</li>
                <li>(3)個人情報へのアクセス権限の管理</li>
                <li>(4)個人情報の漏洩等防止策</li>
                <li>(5)個人情報へのアクセスの記録及び分析</li>
                <li>(6)個人情報を取り扱う情報システムの稼働状況の記録及び分析</li>
                <li>(7)個人情報を取り扱う情報システムの監視及び監査</li>
              </ol>
       
        </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第８条（開示、訂正、利用停止等の請求）</h2>
          

            <li>
              １ 当社は、お客様ご本人から利用目的の通知、開示、内容の訂正、追加又は削除、利用停止、消去及び第三者への提供の停止（以下、「開示等」とします。）を求められた場合は、個人情報について社内調査を行い、遅滞なくご回答させていただきます。
            </li>
            <li>
              ２ 前項にかかわらず、開示等に多額の費用を有する場合その他開示等を行うことが困難な場合であって、お客様ご本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
            </li>
            <li>
              ３ 前２項の定めにかかわらず、お客様ご本人からの第１項に基づく開示等の請求に応じられない場合、当社は、当該理由について回答することをもって第１項の回答がなされたものとします。
            </li>
            <li>
              ４ お客様が第１項の定めに基づく開示等の請求を行う場合、下記手順に沿ってご請求ください。ただし、ご請求形式・内容について不備・不足事項等がある場合、ご請求を受け付けることができない場合がございます。なお、個人情報の開示又は利用目的の通知の請求について、当該お申し立て1回ごとに事務手数料として1000円をご負担いただきます。
            </li>
            <li>
              ５ お客様が第１項の定めに基づき開示等の請求を行った場合、「個人情報開示等請求書」及び「委任状」等記載の個人情報は開示等にのみ利用し、その目的以外の利用は行いません。
            </li>
            <li>ご本人が請求する場合</li>
              <ol className={styles.indentedList}><li><a href="https://drive.google.com/file/d/1CY8Kj4_nrE5VaBoJHaTmZyAvloKDvONM/view">(1)個人情報開示等請求書</a></li></ol>
              <li>上記ファイルをダウンロードの上、必要事項を記入してください。</li>
              <ol className={styles.indentedList}><li>(2)本人確認書類</li></ol>
              <li>パスポート、運転免許証、健康保険証などの、お客様ご本人の氏名、現住所を確認できる書類のコピーをご用意ください。</li>
            <li>代理人が請求する場合</li>
            <p>開示等の請求を行う者が法定代理人、もしくは本人の委任による代理人である場合は、前項の書類に加えて、下記の書類をご添付いただきますようお願いいたします。</p>
            <li>【法定代理人の場合】</li>
              <ol className={styles.indentedList}><li>(1)法定代理権があることを確認するための書類</li></ol>
                <li>戸籍謄本、扶養家族が記入された健康保険の被保険者証のコピー等をご用意ください。</li>
              <ol className={styles.indentedList}><li>(2)法定代理人本人であることを確認するための書類</li></ol>
                <li>パスポート、運転免許証、健康保険証などの、法定代理人本人の氏名、現住所を確認できる書類のコピーをご用意ください。</li>
              <li>【委任による代理人の場合】</li>
              <ol className={styles.indentedList}><li><a href="https://drive.google.com/file/d/1EeCUMMGrN0lS6rphbZevoc8lJIEf5PY4/view">(1)委任状(添付書類)</a></li></ol>
              <li>上記のファイルをダウンロードの上、必要事項をご記入ください。</li>
              <ol className={styles.indentedList}>
                <li>(2)ご本人の印鑑登録証明書（３ヶ月以内に発行されたもの）</li>
                <li>(3)委任による代理人本人であることを確認するための書類</li>
              </ol>
              <li>パスポート、運転免許証、健康保険証などの、法定代理人本人の氏名、現住所を確認できる書類のコピーをご用意ください。</li>
              <p>※なお、開示等に関する詳細は本ポリシー第１６条記載のお問い合わせ窓口までご連絡いただきますようお願いいたします。</p>
        </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第９条（匿名加工情報の取り扱い）</h2>
          <li>
            当社は、匿名加工情報の作成および第三者提供をする場合、個人情報保護委員会規則で定める基準に従い、安全管理のための措置を講じます。
          </li>
        </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第１０条（機微情報の取り扱い）</h2>
          <li>
            当社は、法令又は金融分野における個人情報保護に関するガイドラインに基づく場合等の一定の例外を除いて、機微情報を収集・利用・第三者提供することはありません。
          </li>
        </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第１１条（未成年者の個人情報）</h2>
          <li>
            当社は、未成年者から、その法定代理人の同意なく、みだりに個人情報を収集しないように努めます。
          </li>
        </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第１２条（第三者への個人情報の提供）</h2>
            <li>
              当社は、お客様の個人情報を法令等に基づき必要とされる場合を除き、お客様の同意を得ることなく第三者に提供いたしません。なお、当社は、個人情報を第三者との間で共同利用し、また業務を委託するために個人情報を第三者に預託する場合、当該第三者について調査し必要な契約を締結し、その他法令上必要な措置を講じます
            </li>
            <ol>
            </ol>
        </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第１３条（Cookieその他の技術の使用）</h2>
          
            <li>
              １ 当社サービスでは、Cookie及びこれに類似する技術を利用することがございます。
            </li>
            <li>
              ２ お客様がCookieの受け取りを拒否した場合、当社サービスの機能の一部が制限され、または、利用ができなくなる可能性があることについて、あらかじめ同意したものとみなします。
            </li>
            <li>
              ３ 当社は、Google社が運営する「Google Analytics」によるクッキーを利用した、JPYCサイトにおける閲覧状況等の情報の取得を行っておりますが、「Google Analytics」で収集した情報には、お客様個人を特定できる情報は一切含まれておりません。なお、「Google Analytics」によるデータ収集を無効としたい場合は、以下リンクよりオプトアウトをお願いいたします。 
              <a href="https://tools.google.com/dlpage/gaoptout?hl=ja" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=ja</a>
            </li>
            
            <li>
              ４ 当社サービスは、「Google アナリティクスの広告向けの機能」である「Googleシグナル」を使用しております。Google シグナルを使用すると、Googleアナリティクスでお客様のサイトやアプリから収集した訪問情報と、ログインユーザーのアカウントのGoogle情報が関連付けられます。当サイトと Google Inc.を含む第三者配信事業者は、それらから取得した訪問情報・ログインユーザーのアカウントのGoogle情報等を、 当社サービスを本ポリシー第２条に掲げる目的において利用します。これらの機能はお使いのブラウザの設定からCookieを無効にするか、Google アナリティクス オプトアウト アドオンを利用することで無効にすることができます。
            </li>
          
        </ol>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第１４条（事業責任者・個人情報保護管理者）</h2>
          
          <li>会社名：JPYC株式会社</li>
          <li>住所：〒100-0004 東京都千代田区大手町一丁目6番1号 大手町ビル4階 FINOLAB内</li>
          <li>代表者名：岡部典孝</li>
        </ol>  
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
        <ol className={styles.indentedList}>
          <h2 className={styles.clauseTitle}>第１５条（プライバシーポリシーの変更）</h2>
          
            <li>
              １ 本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。
            </li>
            <li>
              ２ 当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
            </li>
        </ol>
        </div>
        <UnderLine />
            
      <div className={styles.ppBox}>
      <ol className={styles.indentedList}>
        <h2 className={styles.clauseTitle}>第１６条（お問い合わせ窓口）</h2>
        <li>
          当社が保有するお客様の個人情報に関する開示等のお申出、ご意見、ご質問その他の個人情報の取扱いに関するお問い合わせは、以下の窓口までお願いいたします。
        </li>
        <li>会社名 ： JPYC株式会社</li>
        <li>住所 ： 〒100-0004 東京都千代田区大手町一丁目6番1号 大手町ビル4階 FINOLAB内</li>
        <li>連絡先 ： 当社お問い合わせフォーム（<a href="https://jpyc.gitbook.io/jpyc/faq/customer-support" target="_blank" rel="noopener noreferrer">https://jpyc.gitbook.io/jpyc/faq/customer-support</a>）</li>
        </ol>
      </div>
      <UnderLine />
      <div className={styles.ppBox}>
      <ol className={styles.indentedList}>
        <h2 className={styles.clauseTitle}>〈プライバシーポリシーの制定日及び改定日〉</h2>
        <p>制定日：2021年2月2日</p>
        <p>改定日：2021年6月21日</p>
        <p>改定日：2021年9月3日</p>
        <p>改定日：2021年10月25日</p>
        <p>改定日：2023年11月1日</p>
        <p>改定日：2024年9月26日</p>
      </ol>
      </div>
      <UnderLine />
    <Footer />
  </>
)
}

export default PrivacyPolicy