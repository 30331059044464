import React from "react";
import { Routes, Route } from "react-router-dom";
import Fuseiboushi from "./Fuseiboushi";
import Main from "./Top";
import PrivacyPolicy from "./PrivacyPolicy";
import CompensationPolicy from "./CompensationPolicy";
import ShikinKessai from "./ShikinKessai";
import Terms from "./Terms";
import Shohinken from "./Terms/Shohinken";
import Paymerchant from "./Terms/JPYC-PAY/pay-merchant";
import Paypaymentcustomer from "./Terms/JPYC-PAY/pay-payment-customer";

import Tokushoho from "./Tokushoho";

const RoutingConfig = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="terms" element={<Terms />} />
      <Route path="terms/Shohinken" element={<Shohinken />} />
      <Route path="terms/jpyc-pay/pay-merchant" element={<Paymerchant />} />
      <Route path="terms/jpyc-pay/pay-payment-customer" element={<Paypaymentcustomer />} />
      <Route path="tokushoho" element={<Tokushoho />} />
      <Route path="law/:target" element={<Tokushoho />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="compensation-policy" element={<CompensationPolicy />} />
      <Route path="shikin_kessai" element={<ShikinKessai />} />
      <Route path="fuseiboushi" element={<Fuseiboushi />} />
    </Routes>
  );
};

export default RoutingConfig;
