import React from "react";
import styles from "../Tokushoho.module.scss";

const Shop = () => {
  return (
    <div>
      <h2 className={styles.tokushohoTitle}>JPYC販売所</h2>
      <ul className={styles.tokushohoFrame}>
        <li>
          <div className={`${styles.leftBox} ${styles.borderTL}`}>
            <h3>販売業者名</h3>
          </div>
          <div className={`${styles.rightBox} ${styles.borderTR}`}>
            <p>JPYC株式会社</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>責任者</h3>
          </div>
          <div className={styles.rightBox}>
            <p>代表取締役 岡部典孝</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>所在地</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              〒100-0004 東京都千代田区大手町一丁目6番1号 大手町ビル4階
              FINOLAB内
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>メールアドレス</h3>
          </div>
          <div className={styles.rightBox}>
            <p>jpyc_support@jcam.co.jp</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>お問合せ</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              <a
                href="https://docs.jpyc.jp/"
                target="_blank"
                rel="noopener"
              >
                個人の方
              </a>
              <br />
              <a
                href="https://jpyc.co.jp/contact"
                target="_blank"
                rel="noopener"
              >
                法人の方
              </a>
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>ウェブサイトURL</h3>
          </div>
          <div className={styles.rightBox}>
            <p>https://jpyc.jp</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>前払式支払手段の名称</h3>
          </div>
          <div className={styles.rightBox}>
            <p>JPYC</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>販売価格</h3>
          </div>
          <div className={styles.rightBox}>
            <p>1JPYC＝1円</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>
              代金の支払時期
              <br />
              申込みの有効期限
            </h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              お申し込み後、1週間以内にお支払いください。
              <br />
              お申込日から1週間以内にご入金を確認できなかった場合はキャンセルされたものとみなし、
              <br />
              購入申込みは取り消させていただきます。
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>お支払い方法</h3>
          </div>
          <div className={styles.rightBox}>
            <ul>
              <li>・銀行振込</li>
              <li>・ETH送金</li>
              <li>・BTC送金</li>
            </ul>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>商品代金以外の必要料金</h3>
          </div>
          <div className={styles.rightBox}>
            <ul>
              <li>・銀行振込の場合</li>
              <li>銀行振込手数料</li>
              <li>・ETH送金の場合</li>
              <li>ネットワーク利用手数料（いわゆるGAS代）</li>
              <li>
                ※JPYCを当社からお客様に送金する際のネットワーク利用手数料（いわゆるGAS代）は頂いておりません。
              </li>
              <li>※JPYCは前払式支払手段のため非課税</li>
            </ul>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>引き渡し期間</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              お客様のご入金を確認後、原則翌営業日までにご指定のETHアドレス宛に送金致します。
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>返品・交換・キャンセル </h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              購入・手続き完了後の返品・交換・キャンセルはお受けできません。
            </p>
          </div>
        </li>
        <li>
          <div className={`${styles.leftBox} ${styles.borderBL}`}>
            <h3>払戻しについて</h3>
          </div>
          <div className={`${styles.rightBox} ${styles.borderBR}`}>
            <p>
              資金決済に関する法律に基づき払戻しが認められる場合を除き、払戻しはできません。
            </p>
          </div>
        </li>
      </ul>
      <p className={`${styles.tokushoNotice}`}>
        ※連絡先電話番号については、お問い合わせフォームよりご請求いただければ、遅滞なく開示いたします。
      </p>
    </div>
  );
};

export default Shop;
