import styles from "../Tokushoho.module.scss";

const MatsuyaGinza = () => {
  return (
    <div>
      <h2 className={styles.tokushohoTitle}>松屋銀座</h2>
      <ul className={styles.tokushohoFrame}>
        <li>
          <div className={`${styles.leftBox} ${styles.borderTL}`}>
            <h3>販売業者名</h3>
          </div>
          <div className={`${styles.rightBox} ${styles.borderTR}`}>
            <p>JPYC株式会社</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>責任者</h3>
          </div>
          <div className={styles.rightBox}>
            <p>代表取締役 岡部典孝</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>所在地</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              〒100-0004 東京都千代田区大手町一丁目6番1号 大手町ビル4階
              FINOLAB内
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>メールアドレス</h3>
          </div>
          <div className={styles.rightBox}>
            <p>jpyc_matsuya@jcam.co.jp</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>商品の販売価格</h3>
          </div>
          <div className={styles.rightBox}>
            <p>松屋銀座店頭での表示価格</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>商品代金以外の必要料金</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              JPYCを弊社に送信する際のネットワーク利用手数料（いわゆるGAS代）
              <br />
              配送料（配送の場合）
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>支払い方法</h3>
          </div>
          <div className={styles.rightBox}>
            <p>JPYCによる決済</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>
              支払時期
              <br />
              申込みの有効期限
            </h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              松屋銀座店頭での商品取り置き当日の午後12時までに、申込みフォームへご入力のうえ、料金をJPYCにてご送信ください。
              <br />
              お取り置き当日午後12時までにフォームへの入力及びJPYCの送信が確認できなかった場合、購入申込みは無効とさせていただきます。
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>引渡時期</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              （ご来店の場合）松屋銀座にて決済確認日の午後2時以降の商品渡しとなります。ただし基本的に、決済確認は送金いただいた翌日以降となります。
              <br />
              （ご配送の場合）決済の確認日以降の配送手配となります。
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>返品・交換・キャンセル</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              申込みフォーム入力後のお客様都合による返品・交換・キャンセルはお受けできません。
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>注意事項</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              商品を確保できない場合など、当社の判断により契約の全部又は一部を解除させて頂く場合があります。
            </p>
          </div>
        </li>
        <li>
          <div className={`${styles.leftBox} ${styles.borderBL}`}>
            <h3>取扱対象外商品</h3>
          </div>
          <div className={`${styles.rightBox} ${styles.borderBR}`}>
            <p>金券（ギフトカード）、酒類、中古品、200万円以上の商品</p>
          </div>
        </li>
      </ul>
      <p className={`${styles.tokushoNotice}`}>
        ※連絡先電話番号については、お問い合わせフォームよりご請求いただければ、遅滞なく開示いたします。
      </p>
    </div>
  );
};

export default MatsuyaGinza;
