import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FooterSection from '../common/Footer';
import Header from '../common/Header';
import PagesHeader from '../common/PagesHeader';
import styles from './ShikinKessai.module.scss';

const RedirectComponent = () => {
  React.useEffect(() => {
    window.location.href = "https://jpyc.co.jp/settlement";
  }, []);

  return <div>Redirecting...</div>;
};

const ShikinKessai = () => {
  return (
    <>
      <Header />
      <PagesHeader title={'資金決済法に基づく表示'} />
      <div className={styles.shikinkessaiWrapper}>
      </div>
      <Routes>
        <Route path="*" element={<RedirectComponent />} />
      </Routes>
      <FooterSection />
    </>
  );
};

export default ShikinKessai;
