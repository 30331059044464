import React from 'react'
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import PagesHeader from '../../common/PagesHeader'
import UnderLine from '../../common/Props'
import styles from '../Terms.module.scss'

const Terms = () => {
  return (
    <>
      <Header />
      <PagesHeader title={"JPYC Pay 利用者向け利用規約"} />
      <div className={styles.termsWrapper}>
        <ul className={styles.termsBox}>
          <h3 className={styles.clauseTitle}>Comming Soon...</h3>
          <h2 className={styles.clauseTitle}>サービスページは <a href='https://merchant.jpyc.jp/'>こちら</a></h2>

        </ul>
        <UnderLine />
      </div>
      <Footer />
    </>
  )
}

export default Terms