import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import PagesHeader from "../common/PagesHeader";
import UnderLine from "../common/Props";
import styles from "./Terms.module.scss";

const Terms = () => {
  return (
    <>
      <Header />
      <PagesHeader title={"JPYC利用規約"} />
      <div className={styles.termsWrapper}>
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第1条（適用範囲）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              本利用規約（以下「本規約」といいます。）は、ＪＰＹＣ株式会社（以下「当社」といいます。）が前払式支払手段として発行する日本円連動ステーブルコインJPY Coin（以下「JPYC」といいます。）に関する取扱いについて定めるものです。
            </li>
            <li className={styles.termsLi}>
              本規約は、JPYCを購入しようとする者、JPYCの購入者、JPYCの保有者、JPYCを利用しようとする者並びにJPYCを利用する者（以下「利用者」といいます。）に対して適用されます。利用者は、本規約の内容を十分に理解し、本規約に同意することでJPYCを利用できるものとします。
            </li>
            <li className={styles.termsLi}>
              当社が当社サービスサイト上で掲載するホワイトペーパー等、JPYCの利用に関するルールは、本規約の一部を構成するものとします。
            </li>
            <li className={styles.termsLi}>
              本規約の内容と、前項のルールその他の本規約外における事項が矛盾する場合は、本規約の規定が優先して適用されるものとします。ただし、個別の取引について別途明示的に記載した定めは、その定めが当該取引に適用されるものとします。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第2条（JPYCの利用について）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              利用者はJPYCを、利用者の指図に基づき当社を通じた代理購入やギフト券への交換など、当社所定の方法で利用することができます。
            </li>
            <li className={styles.termsLi}>
              利用者がJPYCを利用したことをもって、本規約に同意したものとみなします。
            </li>
            <li className={styles.termsLi}>
              未成年者、成年被後見人、被保佐人又は被補助人である者は、法定代理人による同意を得た上で、JPYCを利用するものとします。
            </li>
            <li className={styles.termsLi}>
              JPYC購入API（JPYCの購入申込みをすることができるプログラム）を利用する場合には、当社ウェブサイトに掲載している「JPYC購入API開発者向けドキュメント」に従って利用するものとします。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第3条（JPYCの購入）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
            JPYCの購入を希望する利用者は、本規約の内容を承諾の上、当社所定の方法により、JPYC購入のための申込みを行うものとします。
            </li>
            <li className={styles.termsLi}>
            次の各号に掲げる者は、本サービスを利用することができず、JPYC購入のための申込みを行うこともできません。
              <ol className={styles.termsInnerOl_1}>
                <li className={styles.termsLi}>
                過去に本規約に違反したことがある者
                </li>
                <li className={styles.termsLi}>
                次項の資料の提供に応じない者
                </li>
                <li className={styles.termsLi}>
                前各号のほか当社が不適当と認める者
                </li>
                </ol>
            </li>
            <li className={styles.termsLi}>
              利用者は、当社が第1項による申込みの審査をするために必要な資料の提供を求めたとき、これに応じるものとします。
            </li>
            <li className={styles.termsLi}>
              当社は、利用者から第1項の申込みを受け、JPYCの対価を受領した場合、当該利用者に対してJPYCを移転させ、JPYCの売買契約を成立させるものとします。ただし、次に掲げる場合、当社は、当該利用者に対してJPYCを移転させず、JPYCの売買契約のための申込みを承諾しません。
              <ol className={styles.termsInnerOl_1}>
                <li className={styles.termsLi}>
                利用者が第2項各号に掲げる者に該当する場合
                </li>
                <li className={styles.termsLi}>
                第1項に基づくJPYCの購入申込み後、その対価が７営業日以内に当社所定の方法により支払われない場合
                </li>
                <li className={styles.termsLi}>
                利用者が入力した情報に不足又は誤りがあり、別途当社から連絡をとったにもかかわらず、連絡が取ることができない場合、又は正確な情報を受領することができない場合
                </li>
                <li className={styles.termsLi}>
                第15条第2項により実施する確認等ができなかった場合
                </li>
                <li className={styles.termsLi}>
                その他不適切な申込みであると当社が判断した場合
                </li>
                </ol>
            </li>
            <li className={styles.termsLi}>
              当社は、利用者が当社に通知したアドレスに宛ててJPYCを移転させるトランザクションを実行し、当該アドレスに係るアカウントに対してJPYCを移転させます。かかるトランザクションの実行が完了し、トランザクションの内容がブロックチェーン上に記録された時、当該利用者に対してJPYCが移転されたものとみなします。
            </li>
            <li className={styles.termsLi}>
              ブロックチェーンのブロックの生成状況その他の状況により、JPYCの移転が遅滞する場合があります。また、当社が別途表示した場合その他合理的な理由がある場合には、JPYCの移転完了まで時間を要することがあります。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第4条（JPYCの購入対価の支払）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              利用者は、銀行振込又は当社が定める暗号資産の送信の方法により、JPYC購入のための対価を支払うことができます。
            </li>
            <li className={styles.termsLi}>
              JPYCの購入は、１円単位で行うことができ、上限額はありません。なお、JPYCを購入できる最低額は3,000円となります。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第5条（JPYCの使用について）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              利用者は、当社から物品等を購入し、若しくは借り受け、又は役務の提供を受けるときにこれらの代価の弁済のためにJPYCを使用することを希望する場合、本規約の内容を承諾の上、当社所定の方法により、JPYCを使用するための申込みを行うものとします
            </li>
            <li className={styles.termsLi}>
              当社は、利用者から当社指定のアドレスに対して、前項の代価に対応する数量のJPYCを受領した場合に限り、受領したJPYCの数量に相当する代価の支払いがあったものとみなします。
            </li>
            <li className={styles.termsLi}>
              利用者から受領したJPYCの数量が、第１項の代価の弁済のために必要な数量と一致しない場合には、第1項の申込みは無効となり、当社は、利用者に対し、次条第に基づき、JPYCを返還します。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第6条（JPYCの返還）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              当社が利用者から受領したJPYCを返還する場合、受領したJPYCの数量から、返信手数料として1,000JPYCを控除した数量のJPYCを、返還します。ただし、返信手数料として控除する数量が、利用者から受領したJPYCの数量を上回る場合は、利用者へのJPYCの返還は行いません。
            </li>
            <li className={styles.termsLi}>
              当社は、送信元アドレスに宛ててJPYCを移転させるトランザクションを実行し、当該アドレスに係るアカウントに対してJPYCを返還します。かかるトランザクションの実行が完了し、トランザクションの内容がブロックチェーン上に記録された時、当該利用者に対してJPYCが移転されたものとみなします。
            </li>
            <li className={styles.termsLi}>
              ブロックチェーンのブロックの生成状況その他の状況により、JPYCの返還が遅滞する場合があります。また、当社が別途表示した場合その他合理的な理由がある場合には、JPYCの返還の完了まで時間を要することがあります。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第7条（JPYCの残高の確認方法）</h2>
          <p>
          利用者の保有するJPYCの残高は、ブロックチェーンノード、エクスプローラー、ウォレットその他の外部サービスを通じて確認することができます。ただし、利用者は、JPYCを利用するにあたって、これらの外部サービスを利用する必要がある場合であっても、当社がこれらの外部サービスを運営又は維持するものではないことを認識し、自己の責任で、これらの外部サービスを利用するものとします。当社は当該外部サービスに起因する利用者の損失又は損害について責任を負いません。
          </p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第8条（JPYCの払戻し等）</h2>
           <p>
           当社は、JPYCの払戻し又は換金を行いません。ただし、経済情勢の変化、法令の改廃その他当社の都合によりJPYCの取扱いを全面的に又はその一部につき廃止した場合（以下「廃止等」といいます。）、その他資金決済に関する法律に基づき払戻しが認められる場合、当社は、JPYCの払戻しを行う場合があります。
           </p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第9条（JPYCの利用停止等）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              当社は、次の各号のいずれかの行為（それらを誘発する行為や準備行為を含みます。）を禁止しています。
              <ol className={styles.termsInnerOl_2}>
                <li>本規約に違反する行為</li>
                <li>
                  利用者が不正な方法によりJPYCを取得し、又は不正な方法で取得されたJPYCであることを知って利用すること
                </li>
                <li>
                  マネー・ロンダリングに関連する行為もしくは法令に違反する行為又は犯罪行為に関連する行為
                </li>
                <li>
                  当社、利用者その他の関係者に対する詐欺ないし脅迫行為
                </li>
                <li>
                  公序良俗に反する行為
                </li>
                <li>
                  当社、利用者その他の関係者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
                </li>
                <li>
                  当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングないし解析行為
                </li>
                <li>当社の運営を妨害するおそれのある行為</li>
                <li>当社のネットワーク又はシステム等への不正アクセス</li>
                <li>第三者に成りすます行為</li>
                <li>虚偽の情報を入力・申告等する行為</li>
                <li>当社が事前に許諾しない宣伝、広告、勧誘、又は営業行為</li>
                <li>前各号に準ずる行為であって、当社が不適切であると判断する一切の行為</li>
              </ol>
            </li>
            <li className={styles.termsLi}>
              当社は、利用者が、前各号のいずれかの事由に該当する場合又はその合理的な疑いを認めた場合には、事前に通知又は催告することなく、当該利用者についてJPYCの利用の全部または一部を停止することができます。
            </li>
            <li className={styles.termsLi}>
              前項の場合、利用者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第10条（JPYCの利用期間）</h2>
          <p>
            JPYCは、期間の制限なく利用することができます。ただし、当社が本規約に基づき、JPYCの利用、保有ないし取扱いを全面的に又はその一部につき制限又は停止させる場合はこの限りではありません。
          </p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>
            第11条（JPYCの取扱いの中止又は中断）
          </h2>
          <p>
            当社は、システムの保守、通信回線又は通信手段、コンピュータの障害などによるシステムの中止又は中断の必要があると認めたときは、利用者に事前に通知することなく、JPYCの取扱いを中止又は中断することができるものとします。
          </p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第12条（保証の否認及び免責）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              当社は、利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者によるJPYCの利用が利用者に適用のある法令又は業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。
            </li>
            <li className={styles.termsLi}>
              当社は、利用者によるJPYCの保有又は利用、JPYCに関連する当社のサービスの停止又は終了、本規約の変更、その他JPYCに起因する事由によって利用者が損害を被った場合、当社に故意又は過失がある場合を除き、当社は損害を賠償する責任を負いません。また、当社債務不履行又は不法行為によりJPYCに関連して利用者に損害が生じた場合、当社に故意又は重過失がある場合を除き、当社は過去6ヶ月間に利用者が当社に支払った対価の金額を上限として、通常生ずべき損害（付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害その他特別の事情によって生じた損害を含みません。）の範囲内で、責任を負うものとします。
            </li>
            <li className={styles.termsLi}>
              JPYCに関連して利用者と他の利用者又は第三者との間において生じた取引、連絡、紛争等については、利用者が自己の責任によって解決するものとします。
            </li>
            <li className={styles.termsLi}>
              当社は、利用者がJPYCを利用したことに関連して取得した情報を保存又は開示する義務を負わないものとします。ただし、当社のプライバシーポリシーに定めのある場合はこの限りではありません。
            </li>
            <li className={styles.termsLi}>
              火災、停電、その他事故、地震、その他天災、戦争、政変、その他これらに類する非常事態、基幹通信事業者などに起因するネットワーク障害、法令に基づく指示・命令、利用者又は第三者の行為に起因する事態、その他これらに準ずる不測の事態によってJPYCの利用が困難となった場合、当社は、これに起因して利用者に生ずる損害及びその他の不利益について、責任を負わないものとします。
            </li>
            <li className={styles.termsLi}>
              申込みに際し利用者が入力した送信先アドレスに誤りがあった場合、利用者がJPYCを紛失した場合、JPYCが盗難、偽造もしくは変造された場合、又は利用者の許可なくJPYCが第三者に使用された場合であっても、当社は責任を負わないものとします。
            </li>
            <li className={styles.termsLi}>
              JPYCを利用又は保有したとしても、利息が付与されるわけではありません。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第13条（損害賠償）</h2>
          <p>
          利用者の本規約に反した行為又は不正もしくは違法な行為により、当社が損害を受けた場合には、当該利用者に対して損害の賠償を請求する場合があります。
          </p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第14条 (返金ポリシー)</h2>

            <ol className={styles.termsInnerOl_2}>
              <li>利用者の責に帰すべき事由により、利用者が想定していたものとは異なるアドレス及びチェーンに対して暗号資産の送信が行われた場合、当社は、当該暗号資産の回復処理（当該暗号資産を回収し、利用者に対して払戻す処理をいいます。）を行いません。
              </li>
              <li>
                前項にかかわらず、当社は、利用者から当社所定の方法により依頼があったとき、調査手数料11,000円(税込)の支払を受けた場合に限り、暗号資産の回復処理が可能であるかの調査を行います。
              </li>
              <li>
                前項の依頼を受けた場合であっても、当社は、利用者に対して回復処理の実現可能性を確約するものではありません。
              </li>
              <li>
                第2項による調査の結果、回復処理が可能であると判断した場合、当社は、回復処理の手数料の支払を受けた場合に限り、回復処理を行います。なお、回復処理の手数料は、55,000円(税込)又は該当暗号資産の価値の10%に相当する金額のうち、高い金額となります。
              </li>
            </ol>
          
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>
            第15条（不正利用に対する取扱い）
          </h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              当社は、JPYCの盗難、紛失、改ざん等により、利用者に生じた損失について、その責任を負いません。ただし、不正利用の有無ないし原因、本規約の違反の有無、又は故意過失の有無等に関する調査結果により、利用者の保護が必要である場合には、その損失を補償する等の対応を個別に検討する場合があります。
            </li>
            <li className={styles.termsLi}>
              当社は、JPYCの不正利用防止のため、利用者に対し、必要な確認を実施する場合があります。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第16条（知的財産）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              当社がJPYCの発行に関連して提供する情報、プログラム、コンテンツ又はソフトウェアに関する知的財産権は、当社又は権利者に帰属しており、利用者は、当社から特に許諾される場合を除き、これらを当社又は権利者に無断で転載等の利用をすることはできません。
            </li>
            <li className={styles.termsLi}>
              利用者が前項に違反した場合、当社は、JPYCの利用を差し止め、当該行為によって生じた損害を請求できるものとします。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第17条（秘密保持）</h2>
          <p>
            利用者は、当社が利用者に対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします
          </p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第18条（利用者情報の取扱い）</h2>
            <p>
              当社は、別途定めるプライバシーポリシー等の定めに従って利用者の情報を取り扱います。
            </p> 
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第19条（通知）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              利用者からの問い合わせその他利用者から当社に対する通知、及び本規約の変更に関する通知その他当社から利用者に対する通知は、当社の定める方法で行うものとします。
            </li>
            <li className={styles.termsLi}>
              利用者が登録した情報に誤りがあったために当社が利用者に通知することができなかった場合、これに起因して利用者に生じる損害及びその他の不利益について当社は責任を負わないものとします。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第20条（委託）</h2>
          <p>
          当社は、JPYCの提供に関して、その全部又は一部を、第三者（外国にいる第三者も含みます。）に委託することができるものとします。
          </p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>
            第21条（本規約上の地位の譲渡等）
          </h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              利用者は、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
            </li>
            <li className={styles.termsLi}>
              当社はJPYCに関する事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに利用者の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第22条（反社会的勢力の排除）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              利用者及び当社は、次の各号のいずれにも該当しないことを保証します。
              <ol className={styles.termsInnerOl_2}>
                <li>
                  自己、並びに自己の役員及び従業員が、暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動標ぼうゴロ、特殊知能暴力集団等、又はこれらに準ずる者（以下これらを総称して「反社会的勢力」といいます。）であること
                </li>
                <li>反社会的勢力が経営に実質的に関与していると認められる関係を有すること</li>
                <li>反社会的勢力を利用していると認められる関係を有すること</li>
                <li>反社会的勢力に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること</li>
                <li>役員又は経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有すること</li>
              </ol>
            </li>
            <li className={styles.termsLi}>
              利用者は、自己又は第三者を利用して以下の各号の一にでも該当する行為をしないことを表明し、保証するものとします。
              <ol className={styles.termsInnerOl_2}>
                <li>
                  暴力的な要求行為
                </li>
                <li>
                  法的な責任を超えた不当な要求行為
                </li>
                <li>
                  取引に関して脅迫的な言動をし、又は、暴力を用いる行為
                </li>
                <li>
                  風説を流布し、偽計又は威力を用いて相手方の信用を毀損又は業務を妨害する行為
                </li>
                <li>
                  その他前各号に準ずる行為
                </li>
              </ol>
            </li>
            <li className={styles.termsLi}>
              当社は、利用者が前二項のいずれかに反することが判明した場合、何らの通知を要せず、いつでもJPYCの利用停止その他必要な措置を講じることができるものとします。なお、この場合、当社は、利用者に生じた損害又は不利益について一切の責任を負いません。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第23条 (分離可能性)</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              本規約の一部又は全部の規定について、消費者契約法その他法令等により無効又は執行不能と判断された場合であっても、当該無効又は執行不能とされた部分以外については継続して完全な効力を有するものとします。
            </li>
            <li className={styles.termsLi}>
              本規約の一部又は全部の規定について、特定の利用者との間で無効又は執行不能と判断された場合であっても、他の利用者との間での有効性については影響しないものとします。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第24条（本規約の変更）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              当社は、次の各号のいずれかに該当する場合、本規約の内容を変更することができるものとし、変更日以降は当該変更内容が適用されるものとします。
              <ol className={styles.termsInnerOl_1}>
                <li>
                  本規約の変更が、利用者の一般の利益に適合するとき
                </li>
                <li>
                  本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき
                </li>
              </ol>
            </li>
            <li className={styles.termsLi}>
              当社は前項による本規約の変更にあたり、本規約を変更する旨、変更後の本規約の内容について、当社が適切であると判断する方法により周知します。
            </li>
            <li className={styles.termsLi}>
              本規約の変更以降に利用者がJPYCを利用したときは、利用者は、本規約の変更に同意したものとみなします。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第25条（管轄）</h2>
          <ol className={styles.termsOl}>
            <li className={styles.termsLi}>
              本規約の準拠法は日本法とします。
            </li>
            <li className={styles.termsLi}>
              本規約又はJPYCに起因し、又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </li>
          </ol>
        </ul>
        <UnderLine />

        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>附則</h2>
          <p>2021年9月1日制定</p>
          <p>2021年10月26日改定</p>
          <p>2022年12月15日改定</p>
          <p>2023年1月15日改定</p>
          <p>2024年3月2日改定</p>
        </ul>
        <ul className={styles.termsBox}>
          <p className={styles.end}>以上</p>
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
