import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import PagesHeader from "../common/PagesHeader";
import UnderLine from "../common/Props";
import styles from "./CompensationPolicy.module.scss";

const CompensationPolicy = () => {
  return (
    <>
      <Header />
      <PagesHeader title={"不正取引に対する補償方針"} />
      <div className={styles.ppWrapper}>
        <ul className={styles.ppBox}>
          <h2 className={styles.clauseTitle}>
            【損失が発生するおそれのある具体的な場面】
          </h2>
          <ul>
            <li>
              JPYC又はJPYC商品券（以下「JPYC」といいます）が第三者に不正に利用又は処分等されたことにより、JPYC等の利用者（以下「利用者」といいます。）の責めに帰すべき事由によることなく、利用者等に損失が生じた場合
            </li>
          </ul>
        </ul>
        <UnderLine />
        <div className={styles.ppBox}>
          <h2 className={styles.clauseTitle}>【損失の補償の有無】</h2>
          <ul>
            <li>（1）JPYC</li>
            <li>
              当社は、JPYCの利用者の意思に反して権限を有しない者の指図が行われたことにより発生した損失について、原則として、これを補償します。ただし、当社又は連携先に申告した内容、当社及び連携先が行った調査の内容その他の事情を勘案の上、以下のいずれかに該当すると当社が合理的に判断した損失の全部又は一部については補償を行いません。
            </li>
            <ul>
              <li>利用者が秘密鍵を保有するウォレットに起因して発生した損失</li>
              <li>利用者の故意又は過失に起因して発生した損失</li>
              <li>利用者等の同居の家族、親族等の行為に起因して発生した損失</li>
              <li>
                利用者等が当該損失に係る事実について当社に虚偽の説明を行った場合における当該損失
              </li>
              <li>戦争、暴動等の社会秩序の混乱に乗じて発生した損失</li>
            </ul>
            <li>（2）JPYC商品券について</li>
            <li>
              当社は、JPYC商品券の紛失、盗難等により、利用者に生じた損失について、原則として、その責任を負わないものとします。
            </li>
          </ul>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
          <h2 className={styles.clauseTitle}>
            【補償の内容及び補償に要件がある場合にはその内容】
          </h2>
          <ul>
            <li>（1）補償内容</li>
            <li>
              当社は、JPYC等の利用者が被った損失の内容に応じて、JPYC等を付与又は交付する方法により、利用者が被った損失を補償するものとします。ただし、利用者等に過失がある場合は、損失を被った利用者の行為態様やその状況等を考慮の上、補償額を決定することとし、かかる補償額の決定に際しては、預金者保護法および盗難通帳等による預金等の不正な払戻しへの対応に関する全銀協申し合わせ（平成20年2月19日付「預金等の不正な払戻しへの対応について」）等を参考にすることとします。また、利用者が連携先その他当社以外の第三者から損失の補填を受けた場合、当社は、当該補填を受けた金額を差し引いた残額を補償するものとします。
            </li>
            <li>（2）補償に手続的要件を設ける場合</li>
            <li>
              JPYC等の利用者が当社に対して補償を求める場合には、「補償手続の内容」に従った手続を行うとともに、当社による調査に協力するものとします。利用者等が当該手続を怠った場合には、利用者等に生じた損失の全部又は一部について、当社はその責任を負わないことがあります。
            </li>
          </ul>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
          <h2 className={styles.clauseTitle}>【補償手続の内容】</h2>
          <ul>
            <li>
              （1）JPYC等の利用者は、損失が発生した日（継続して複数回の損失が発生した場合はその最終の損失発生日）から30日以内に、当該損失が発生した事実を当社に通知するものとします。また、その被害について、警察署に申告しなければならないものとします。
            </li>
            <li>
              （2）利用者は、前項に基づく当社への通知後速やかに、当社に対して、以下の内容を必要な資料を添付して申告するものとします。
            </li>
            <ul>
              <li>損失額</li>
              <li>損失発生日</li>
              <li>損失発生の経緯</li>
              <li>その他当社が通知を求めた事項</li>
            </ul>
          </ul>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
          <h2 className={styles.clauseTitle}>
            【連携サービス（他の事業者の提供するサービスと連携するサービスをいいます。以下同じ。）を提供する場合にあっては前払式支払手段発行者と連携先の補償の分担に関する事項】
          </h2>
          <ul>
            <li>
              JPYC等の利用者に生じた損失については、原則として、当社が問い合わせ窓口となり、本方針に従って補償を実施するものとします。
            </li>
          </ul>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
          <h2 className={styles.clauseTitle}>
            【補償に関する相談窓口及びその連絡先】
          </h2>
          <ul>
            <li>
              所在地 ：〒100-0004 東京都千代田区大手町一丁目6番1号 大手町ビル4階
              FINOLAB内
            </li>
            <li>
              問い合わせ先 ：
              <a href="https://jpyc.co.jp/contact" target="_blank">
                お問い合わせフォーム
              </a>
              よりお問合せください。
            </li>
            <li>電話応対時間 ：平日10時～18時まで（年末・年始を除く）</li>
          </ul>
        </div>
        <UnderLine />
        <div className={styles.ppBox}>
          <h2 className={styles.clauseTitle}>【不正取引の公表基準】</h2>
          <ul>
            <li>
              当社は、不正取引が発生した場合又はそのおそれがある場合について、当該不正取引の態様を踏まえ、被害の拡大（二次被害）を防止するために必要があると判断したとき、類似の事案の発生を回避するために有益であると判断したとき、また、被害額や件数等の事情において社会的な影響が大きいと認められるときは、速やかに必要な情報を公表いたします。
            </li>
          </ul>
        </div>
        <UnderLine />
        <ul className={styles.ppBox}>
          <h2 className={styles.clauseTitle}>附則</h2>
          <p>2022年5月25日制定</p>
          <p>2023年11月13日改訂</p>
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default CompensationPolicy;
