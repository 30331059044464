import React from "react";
import * as ReactDOM from "react-dom";
import "./styles/global.module.css";
import Route from "./components/route";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/common/Props/ScrollToTop";
import "./utils/i18n";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Route />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
