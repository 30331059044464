import React from 'react'
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import PagesHeader from '../../common/PagesHeader'
import UnderLine from '../../common/Props'
import styles from '../Terms.module.scss'

const Terms = () => {
  return (
    <>
      <Header />
      <PagesHeader title={"JPYC Pay 加盟店向け利用規約"} />
      <div className={styles.termsWrapper}>
      <ul className={styles.termsBox}>
        <h2 className={styles.clauseTitle}>第１条（適用範囲)</h2>
        <p>本規約は、JPYC株式会社（以下「当社」という。）の発行するJPYC（第２条第１号に定義する。）により商品又はサービスを提供する加盟店との間の契約関係について定める。加盟店は、本規約の各条項に従い、JPYCにより商品又はサービスを提供することに同意する。</p>
      </ul>
      <UnderLine />
      <ul className={styles.termsBox}>
        <h2 className={styles.clauseTitle}>第２条（定義)</h2>
        <ol>
          <p>
            本規約において、次の用語は以下に規定されたとおりの意義を有するものとする。
              <ol className={styles.termsInnerOl_2}>
                <li>「JPYC」とは、当社が発行する第三者型前払式支払手段である日本円連動ステーブルコインJPY Coinをいう。</li>
                <li>「加盟店」とは、当社所定の手続きに従い、加盟店としての登録の申込みを行い、当社が承認の上加盟店として登録された個人又は法人をいう。</li>
                <li>「利用者」とは､加盟店から商品又はサービスを購入し、JPYCを取引代金の決済に利用する者をいう。</li>
                <li>「加盟店サイト」とは、加盟店が運営するウェブサイトのうち、加盟店が当社に届け出て、当社の承認を得たウェブサイトをいう。</li>
                <li>「対象商品」とは、加盟店が提供する商品又はサービスのうち、加盟店が当社に届け出て、当社の承認を得た商品又はサービスをいう。</li>
                <li>「JPYC取引」とは、利用者が加盟店より、対象商品を購入し又は提供を受けた際に、金銭等に変えてJPYCを利用して対象商品の代金を支払う取引をいう。</li>
                <li>「加盟店契約」とは、当社及び加盟店との間における本規約、本規約に基づき当社が定める規程その他の加盟店に関する規程の総称をいう。</li>
              </ol>
          </p>
        </ol>
      </ul>
      <UnderLine />
      <ul className={styles.termsBox}>
        <h2 className={styles.clauseTitle}>第３条（加盟店契約の成立)</h2>
        <ol>
          <li>加盟店となることを希望する者(以下「申込者」という。)は、当社所定の申込書及び必要書類を当社に提出し、当社所定の登録手続を行うものとする。申込者は、申込書には真実かつ正確な内容を記載しなければならない。</li>
          <li>当社は、前項の手続によって提出された申込書の内容につき、必要な審査を行い、申込者を加盟店としての登録に承諾する場合、申込者を加盟店として登録し、加盟店番号を付与して、当該加盟店番号を申込者に対して通知するものとする。当社から申込者に対して加盟店番号が通知された時点で加盟店契約が成立するものとする。</li>
          <li>当社は、申込者の登録を承諾しなかった場合においても、申込者に対して、拒絶の理由を開示せず、損害賠償その他名目のいかんを問わず何らの義務又は責任を負わないものとする。</li>
        </ol>
      </ul>
      <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第４条（JPYC取引)</h2>
          <ol>
            <li>加盟店は、当社の承認を得た加盟店サイト又は対象商品についてのみJPYCをJPYC取引に利用することができるものとする。</li>
            <li>加盟店は、JPYC取引を行う加盟店サイトについて、あらかじめ当社所定の様式の書面をもって届け出、当社の承認を得るものとする。加盟店サイトの追加、取消しについても同様とする。なお、当社は加盟店に対し事前に書面による通知を行うことにより、加盟店サイトの全部又は一部についてJPYC取引を行うことを禁止することができるものとする。</li>
            <li>加盟店は、利用者からJPYC取引を求められた場合、加盟店契約に従い、正当かつ適法に加盟店サイトにおいてJPYC取引を行うものとする。</li>
            <li>加盟店は、当社が利用者向けに定める利用規約の記載内容を承認し、これに従い利用者とJPYC取引を行うものとする。</li>
            <li>加盟店は、利用者が対象商品の決済にJPYCを利用した場合には、利用者が当該対象商品の代金を支払ったものとして取り扱うものとし、利用者から当該対象商品の代金相当額のJPYCを受領した時点で、加盟店に対する代金債務を免責するものとする。</li>
            <li>加盟店は、JPYC取引を行うにあたっては、当社所定の方法に従い、JPYC取引にかかる情報を記録するものとする。</li>
            <li>加盟店は、１回のJPYC取引を、２つ以上の利用者のウォレットにより行うことはできないものとする。</li>
            <li>加盟店は、システムの障害時、システムの通信時、又はシステムの保守管理に必要な時間及びその他やむを得ない場合には、JPYC取引を行うことができないことをあらかじめ承認するものとする。その場合の逸失利益、機会損失等についてはいかなる場合にも当社は責を負わないものとする。</li>
            <li>加盟店は、有価証券及び金券等をJPYC取引の対象商品とすることができないものとする。当社が、別途指定する商品等についても同様とする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第５条（加盟店の義務等)</h2>
          <ol>
            <li>加盟店は、加盟店契約に定める義務等を加盟店の従業員及びその他加盟店の業務に従事する者に遵守させるものとする。</li>
            <li>当社は、加盟店サイト又は加盟店の従業員及びその他加盟店の業務に従事する者が、JPYC取引に関連して行った行為及び加盟店サイト又は加盟店の従業員、その他加盟店の業務を行う者の果たすべき義務を、全て加盟店の行為とみなすことができる。</li>
            <li>加盟店が加盟店契約に定める手続によらずJPYC取引を行った場合には、加盟店がその一切の責任を負うものとする。</li>
            <li>加盟店は、当社からJPYC取引に関する資料を提出するよう請求があった場合には、速やかにその資料を提出するものとする。</li>
            <li>加盟店は、当社と利用者との契約関係を承認し、JPYCに関するシステムの円滑な運営及びJPYC取引の普及向上に協力するものとする。また、加盟店は、当社よりJPYCの利用促進にかかる掲示の要請を受けたときは、これに協力するものとする。</li>
            <li>加盟店は、JPYC取引に関する情報を加盟店契約に定める用途以外の目的で使用してはならないものとし、これを第三者に使用させてはならないものとする。</li>
            <li>当社及び加盟店は、当社がJPYC取引を行うことができる加盟店を公表する場合、加盟店契約により認められている場合又は相手方の事前の書面による承諾を得た場合を除き、相手方の業務にかかる氏名、商号、商標、標章その他の商品又は営業に関する一切の表示を使用しないものとし、これらの相手方の表示と誤認、混同を生じさせる表示をしないものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第６条（加盟店マークの表示)</h2>
          <ol>
            <li>加盟店は、加盟店サイト上に当社所定の加盟店マーク（JPYC）を表示するものとする。ただし、当社が加盟店マーク（JPYC）の使用を中止若しくは禁止した場合、又は当社が加盟店マーク（JPYC）を変更した場合は、加盟店は異議なくこれに応じるものとする。</li>
            <li>加盟店は、加盟店マーク（JPYC）を本規約に定める用途以外の目的で使用してはならないものとし、これを第三者に使用させてはならないものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第７条（法令の遵守)</h2>
          <ol>
            <li>加盟店は、対象商品の提供にあたっては、特定商取引に関する法律、景品表示法、著作権法、資金決済に関する法律その他の法令又は規制を厳に遵守し、これらの法令又は規制に違反してはならない。</li>
            <li>加盟店は、当社が前項の遵守状況を確認するために必要な資料の提出を求めた場合、速やかにこれを提出するものとする。</li>
            <li>加盟店は、加盟店サイトにおいて、利用者に対し、対象商品に関する品質、価格その他の取引条件に関し、誤認を与える表示をしないものとする。</li>
            <li>加盟店は、JPYCを利用して、法令その他の規制により許認可又は届出が必要となる対象商品の提供を行う場合、監督官庁から交付を受けた許認可証又は届出書等の写しを当社に提出するものとし､かかる許認可又は届出が取消し又は無効となった場合には、当該対象商品にかかるJPYCの利用を停止するものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第８条（第三者への委託)</h2>
          <ol>
            <li>加盟店は、当社が別途書面により事前に承諾した場合を除き、加盟店契約に基づいて行う業務を第三者に委託することができないものとする。</li>
            <li>加盟店は、当社がJPYCの管理業務の一部又は全部を第三者に委託することにつき、事前に同意するものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第９条（JPYC取引の円滑な実施)</h2>
          <ol>
            <li>加盟店は、利用者に対し、JPYCにより対象商品の提供を行う場合には、現金その他の支払手段を用いる利用者より不利な取扱いを行ってはならない。</li>
            <li>加盟店は、当社がJPYCの利用状況等につき調査を行う場合においては、これに必要な協力を行うものとする。</li>
            <li>加盟店は、JPYCの偽造、変造その他の不正利用を防止するため、善良なる管理者の注意をもって必要な措置を講じるものとする。</li>
            <li>加盟店は、利用者からの対象商品に関する問い合わせ又は苦情等に対応する窓口を設置の上､自己の責任において利用者からの問い合わせ又は苦情等に対応するものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第１０条（禁止事項)</h2>
          <ol>
            <li>加盟店は、加盟店サイトにおいて次に掲げる行為を行ってはならない。
            <ol className={styles.termsInnerOl_2}> 
                <li>詐欺等の犯罪に結びつく行為</li>
                <li>第三者(加盟店の顧客やJPYCを利用する他の加盟店を含むが、これらに限られない。以下同じ。)の著作権、商標権等の知的財産権を侵害する行為、又は侵害するおそれのある行為</li>
                <li>第三者の財産、プライバシー若しくは肖像権を侵害する行為、又は侵害するおそれのある行為</li>
                <li>有害なコンピュータプログラム等を送信し、又は第三者が受信可能な状態におく行為</li>
                <li>政治団体、宗教団体その他の団体への加入若しくは寄付を勧誘し、又は選挙の事前運動、選挙運動及び公職選挙法に抵触する行為(これらに類似する行為を含む。)</li>
                <li>わいせつ物頒布等罪、児童買春・児童ポルノ禁止法違反の罪、児童虐待防止法違反の罪、児童福祉法違反の罪、売春防止法違反の罪、賭博罪、その他これらに準ずる犯罪に関係する画像、動画、文書等を送信若しくは表示する行為</li>
                <li>第三者に対し、無断で、広告・宣伝・勧誘等の電子メール又は嫌悪感を抱く電子メール(そのおそれのある電子メールを含む。)を送信する行為、第三者のメール受信を妨害する行為又は連鎖的なメール転送を依頼する行為及び当該依頼に応じて転送する行為</li>
                <li>通常利用の範囲を超えてサーバに負荷をかける行為若しくはそれを助長するような行為、その他当社の運営・JPYCの提供若しくは他の加盟店によるJPYCの利用を妨害し、又はそれらに支障をきたす行為</li>
                <li>本人の同意を得ることなく又は詐欺的な手段(いわゆるフィッシング及びこれに類する手段を含む。)により第三者の情報を取得する行為</li>
                <li>当社又は第三者に不利益を与え、当社又は第三者を誹謗中傷し、又はこれらの営業を妨害する行為</li>
                <li>上記各号の他、法令又は本規約に違反する行為、又は公序良俗に違反する行為</li>
                <li>上記各号のいずれかに該当するもの(第三者が行っている場合を含む。)にこれらを助長する目的でリンクを張る行為</li>
                <li>その他当社が不適当と判断した行為</li>
             </ol> 
             </li>
            <li>当社は、加盟店の行為又は加盟店が提供する対象商品が前項各号のいずれかに該当すると判断した場合には、加盟店に対し、是正を要請することができるものとし、加盟店は速やかにこれに応じなければならないものとする。</li>
            <li>加盟店は、対象商品を、利用者に提示した条件に従い提供するものとする。加盟店は､対象商品に関連する一切の事項について責任を負うものとし､JPYCを利用してなされた対象商品の提供及びその結果についてその責任を負うものとする。</li>
            <li>加盟店は、JPYCを利用してなされた対象商品の提供に関し、他の利用者その他の第三者及び当社に損害又は不利益を与えた場合、自己の責任と費用においてこれを解決するものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第１１条（JPYC取引の中止及び停止)</h2>
          <ol>
            <li>
              当社は、JPYC取引に関連するシステムを管理運営し、JPYC取引による決済業務を行うものとする。ただし、加盟店が次に掲げるいずれかの事由に該当する場合､当社は、加盟店によるJPYC取引及びJPYC取引に関連するシステムの利用及び決済業務を留保し又は拒否することができるものとする。
                <ol className={styles.termsInnerOl_2}>
                  <li>加盟店が加盟店契約に違反し、又は違反するおそれがある場合</li>
                  <li>加盟店が当社に提出した申込書又は届出書その他の書類の内容に虚偽又は不正確な記載があることが判明した場合</li>
                  <li>JPYCの利用につき不正行為が行われ、又は行われたおそれがある場合において、加盟店が当該不正行為の事実を知り、又は重大な過失により知らなかった場合</li>
                  <li>上記のほか、当社が合理的に不適切であると判断した場合</li>
                </ol>
            </li>
            <li>当社は、システムの保守、通信回線又は通信手段、コンピュータの障害などによるシステムの中止又は停止の必要があると認めたときは､加盟店に事前に通知の上、JPYC取引に関連するシステムの利用を中止又は停止することができるものとする。ただし、緊急やむを得ない場合は、加盟店への事前の通知なく、JPYC取引に関連するシステムの利用を中止又は停止することができるものとする｡</li>
            <li>当社が合理的に必要と判断した場合には、いつでもJPYC取引に関連するシステムの内容を変更し、又はJPYC取引に関連するシステムの利用を中止又は停止することができるものとする。これにより、加盟店のJPYC取引に関連するシステムの利用に重大な影響が生じる可能性があるときは、当社は加盟店に対して事前に通知をするものとする。ただし、緊急やむを得ない場合は、加盟店への事前の通知なく、JPYC取引に関連するシステムの内容を変更し、又はJPYC取引に関連するシステムの利用を中止又は停止することができるものとする。</li>
            <li>本条の規定に基づくJPYC取引に関連するシステムの利用及び決済業務の留保若しくは拒否又はJPYC取引に関連するシステムの利用の中止若しくは停止により加盟店に生じた損害について、当社は、当社に故意又は重過失がある場合を除き、いかなる責任を負わないものとする。</li>  
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第１２条（届出事項)</h2>
          <ol>
            <li>加盟店は、自らの名称・商号・代表者名・所在地・電話番号・加盟店サイト及びJPYC取引の精算金の振込指定金融機関口座その他必要な事項（以下これらの事項を合わせて「加盟店情報」という。）に変更が生じた場合には、直ちに当社へ届け出を行い、当社の承認を得るものとする。</li>
            <li>前項の届け出がないために、当社からの通知、送付書類若しくは決済代金が延着し、又は到着しなかった場合には、通常到着すべき時に加盟店に到着したものとみなすことができる。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第１３条（不正に取得されたJPYCに関する取扱い)</h2>
          <ol>
            <li>加盟店は、JPYC取引において、利用者から受領したJPYCが盗取その他の手段により違法に取得されたものであることが判明した場合には、JPYC取引を拒絶し、当社の指定する方法により、当社にその旨を速やかに報告するとともに、当該JPYCについて、当社の指示に従った取扱いを行うものとする。</li>
            <li>加盟店が前項に違反してJPYC取引を行った場合、加盟店は当社に対し、当該JPYC取引にかかわる売上金額の支払いを請求することができないものとする。</li>
            <li>前項に定める場合であっても、加盟店が第１項に規定する連絡を含む加盟店契約上の義務を遵守した場合には、次に掲げる事項のいずれかに該当する場合を除き、当社は加盟店に対し、当社が確認することができる額を限度として、盗取その他の手段により違法に取得されたJPYCについて金銭による保証を行うことができるものとする。
              <ol className={styles.termsInnerOl_2}>
                <li>加盟店、加盟店の従業員その他加盟店の業務を行う者が故意又は過失により当該JPYCの盗取その他の手段による違法な取得に関与した場合</li>
                <li>加盟店が当該JPYCを受ける際に、当該JPYCが盗取その他の手段による違法な取得をされたものであることを知り、又は重大な過失により知らなかった場合</li>
              </ol>
            </li>
            <li>加盟店は、当社からJPYCの不正利用に関する調査の協力を求められたときには、誠実に協力するものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第１４条（返品等の取扱い)</h2>
          <ol>
            <li>加盟店は、JPYC取引にあたり、返品その他の事情により利用者とのJPYC取引の取消しを行う場合、利用者に対して当該取引代金を現金で払い戻すものとする。ただし、当社が承諾した場合はこの限りではない。</li>
            <li>加盟店は、前項に規定に基づき利用者に対し取引代金を現金で払い戻す場合であったとしても、既に実行したJPYC取引にかかる加盟店手数料を負担するものとし、当社は当該加盟店手数料を返金する義務を負わないものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第１５条（加盟店の設備)</h2>
          <ol>
            <li>加盟店は、JPYC取引を行うために必要な通信機器､ソフトウェアその他これらに付随して必要となるすべての機器を、自己の費用と責任において準備し、利用可能な状態に置くものとする。また、JPYC取引にあたっては、自己の費用と責任において、加盟店が任意に選択し、電気通信サービス又は電気通信回線を経由してインターネットに接続するものとする。</li>
            <li>加盟店は、関係官庁等が提供する情報を参考にして､自己の利用環境に応じ、コンピュータウィルスの感染、不正アクセス及び情報漏洩の防止等セキュリティーを保持するものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第１６条（売上金額の確定)</h2>
          <ol>
            <li>加盟店は、JPYC取引に用いられたJPYCにかかる情報を、当社所定の方法により当社へ伝達することで、JPYC取引に関する売上金額が確定するものとする。</li>
            <li>加盟店は、第４条第５項に規定する時点で、利用者の加盟店に対する代金債務を当社が免責的に引き受けることに同意する。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第１７条（精算方法)</h2>
          <ol>
            <li>当社は、JPYC取引に関する売上金額について、当社所定の取扱期間ごとに集計し、加盟店に対し、当該取扱期間における売上金額の合計額及び加盟店手数料を通知するものとする。</li>
            <li>加盟店は、JPYC取引により利用者が決済した金額に応じ、別途当社及び加盟店との間で定める手数料を支払うものとする。</li>
            <li>加盟店は、JPYC取引により利用者から受領したJPYCを、別途当社の定める方法に従い、当社に対し、返還するものとする。</li>
            <li>当社は、前項に基づく加盟店からのJPYCの返還と引き換えに、加盟店に対し、第１項に定める取扱期間の売上金額の合計から前項の加盟店手数料を差し引いた金額（以下「精算金」という。）を、当社所定の支払日に、加盟店の指定金融機関口座に振り込む方法により支払うものとする。なお、支払日が金融機関の休業日である場合には、その直前の営業日に支払うものとする。</li>
            <li>当社が、加盟店に対し、精算金を支払う際に生じる振込手数料は、加盟店が負担するものとする。</li>
          </ol>
        </ul>
        <UnderLine />
         <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第１８条（売上金額の確認)</h2>
          <ol>
             <li>加盟店は、前条の規定により、当社から売上金額の合計額及び加盟店手数料の通知を受けた場合は、当該通知を確認するものとする。加盟店が、当社による通知の発出した日から３０日以内に当社に連絡をしない場合、当該通知内容を異議なく承諾したものとみなす。</li>
            <li>当社は、JPYC取引に関する売上金額の明細について、加盟店からデータの提供を求められた場合、当社の定める方法により有償で提供するものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第１９条（精算金支払の取消し及び留保)</h2>
          <ol>
            <li>当社は、次に掲げる事項のいずれかに該当する場合、加盟店に対し、当該JPYC取引にかかる精算金の支払い義務を負わないものとする。
              <ol className={styles.termsInnerOl_2}> 
                <li>JPYC取引に用いられたJPYCが盗取その他の手段により違法に取得されたものであるとき</li>
                <li>第４条に違反してJPYC取引を行ったとき</li>
                <li>明らかな不正利用に対してJPYC取引を行ったとき</li>
                <li>その他加盟店が本規約に違反したとき</li>
              </ol>
            </li>
            <li>当社が加盟店に対し精算金を支払った後に、前項各号の事由に該当する事実が判明した場合には、加盟店は直ちに当社の指定する方法により当社に対し当該精算金を返還するものとする。</li>
            <li>加盟店が前項に基づき精算金の返還を行わない場合、当社は、加盟店に対する精算金から当該返還されるべき精算金を差し引くことができるものとする。</li>
            <li>当社は、JPYC取引において使用されたJPYCに関し、第１項各号のいずれかの事実に該当するおそれがある場合には、必要な調査を実施することができるものとし、当該調査が完了するまでは、当該JPYC取引にかかる精算金の支払いを留保することができる。この場合、当社は当該留保期間中の遅延損害金の支払義務を負わないものとする。</li>
            <li>前項の調査開始より３０日を経過した後、当社が第１項各号のいずれかの事実に該当するおそれがなお認められると判断する場合、当社は当該JPYC取引にかかる精算金の支払義務を負わないものとする。ただし、その後の調査により、当社が当該JPYC取引にかかる精算金の支払いを相当と認めたときは、当社は当該精算金を加盟店に支払うものとする。</li>
            <li>加盟店は、前２項に定める調査に誠実に協力するものとし、当社の求めに従い、当該JPYC取引に関する資料を当社に提供するものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第２０条（守秘義務)</h2>
          <ol>
            <li>当社及び加盟店は、本規約に関連して知り得たお互いの技術上、営業上その他一切の情報(以下「秘密情報」という。)を善良な管理者の注意義務をもって秘密として厳重に管理するものとする。また、相手方の事前の書面による同意を得ることなく、第三者に対してこれらの秘密情報を開示し、又はこれらの秘密情報を含む一切の資料を交付しないものとする。</li>
            <li>前項の規定にかかわらず、次の各号のいずれかに該当する情報は秘密情報から除外されるものとする。
              <ol className={styles.termsInnerOl_2}>
                <li>取得以前に既に公知であるもの</li>
                <li>取得後に取得者の責めによらず公知となったもの</li>
                <li>取得以前に既に所有していたものでその事実が立証できるもの</li>
                <li>正当な権限を有する第三者から守秘義務を負わずに入手したもの</li>
              </ol>
            </li>
            <li>当社及び加盟店は、裁判所、政府若しくはその他の行政機関による秘密情報の開示の要請又は命令を受けた場合には、かかる要請又は命令を受けたことを相手方に通知した上で､かかる秘密情報を最小限の範囲で開示することができるものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第２１条（個人情報の取扱い)</h2>
          <ol>
            <li>当社は、当社が加盟店から取得した個人情報に関し、別途定める「個人情報保護方針」及び「個人情報の取扱いについて」に基づき、適切に取り扱うものとする。</li>
            <li>加盟店は、当社がJPYCの管理業務の一部又は全部を第三者に委託する場合、当社が必要な措置を講じた上で、加盟店から取得した個人情報を、委託先に提供し、委託先が委託の範囲内で利用することについて同意するものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第２２条（契約期間)</h2>
          <p>加盟店契約の有効期間は、第３条に基づき加盟店契約が成立した日から１年間とする。ただし、加盟店契約の期間満了日の１ヶ月前までに、当社又は加盟店のいずれからも書面による申し出がないときは、加盟店契約は更に１年問自動的に更新されるものとし、以後も同様とする。</p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第２３条（任意解約)</h2>
          <p>当社又は加盟店は、加盟店契約の有効期間中、相手方に対し、３ヶ月前に書面をもって通知することにより、加盟店契約を解約することができる。</p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}><h2 className={styles.clauseTitle}>第２４条（契約の解除)</h2>
          <ol>
            <li>当社は、加盟店が次の各号に定める事由に該当する場合、加盟店に対し何ら催告その他の手続を要することなく、加盟店契約を直ちに解除することができるものとする。
              <ol className={styles.termsInnerOl_2}>
                <li>本契約に違反し、相当の期間を定めて催告したにもかかわらず、その期間内に違反が是正されないとき</li>
                <li>第３条又は第１２条に基づく届出内容に虚偽の内容が含まれていたとき</li>
                <li>第１９条第２項に基づく精算金の返還を怠ったとき</li>
                <li>加盟店又は加盟店の従業員その他加盟店の業務に従事する者が第２０条の規定に違反したとき</li>
                <li>手形又は小切手の不渡りがあったとき、支払停止になったとき、信用状態に重大な不安が生じたとき</li>
                <li>監督官庁により営業の取消、停止等の処分を受けたとき</li>
                <li>仮差押、仮処分、差押強制執行、競売等の申立を受けたとき</li>
                <li>破産手続開始、再生手続開始、更生手続開始、特別清算開始等の申立を受け、又は自ら申し立てたとき</li>
                <li>合併、解散、減資又は事業の全部若しくは重要な一部の譲渡の決議があったとき</li>
                <li>加盟店及びその役員、従業員、株主その他の関係者が、暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロ又は特殊知能暴力集団等、カルト的宗教団体その他これらに準ずる者であること、又はそれらの可能性があることが判明したとき</li>
                <li>その他信用不安事由が生じ、又は契約を継続しがたい事由が生じたとき</li>
                <li>その他加盟店として不適当と当社が判断したとき</li>
              </ol>
            </li>
            <li>加盟店が前項各号のいずれかに該当する場合、加盟店は、当社に対して負担する一切の債務（加盟店契約による債務に限定されない。）について当然に期限の利益を失い、直ちに当該債務全額を一括して当社に支払うものとする。</li>
            <li>加盟店が第１項各号のいずれかに該当することにより、当社に損害が生じた場合、当該加盟店は、当社に生じた損害を賠償しなければならないものとする。</li>
            <li>当社が第１項に基づき加盟店契約を解除した場合は、加盟店に損害が生じたとしても、当社は一切の損害につき賠償する義務を負わないものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第２５条（反社会的勢力の排除)</h2>
          <ol>
            <li>当社及び加盟店は、自ら並びにその役職員、自己の代理人、自己の媒介する者、自己の主要な出資者及び経営に実質的に関与する者が、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治運動標ぼうゴロ、特殊知能暴力団等、その他これらに準ずるもの（以下総称して「反社会的勢力」という。）でないことを確約する。</li>
            <li>当社又は加盟店は、相手方が次に掲げる事項のいずれかに該当する場合、何らの催告を要せず、加盟店契約を解除することができる。
              <ol className={styles.termsInnerOl_2}>
                <li>反社会的勢力に該当するとき</li>
                <li>反社会的勢力が経営を支配していると認められるとき</li>
                <li>反社会的勢力が経営に実質的に関与していると認められるとき</li>
                <li>自己、自社若しくは第三者の不正の利益を図る目的で又は第三者に損害を加える目的で不当に反社会的勢力を利用したと認められるとき</li>
                <li>反社会的勢力に対して資金等を提供し、又は便宜を供与するなどの関与が認められるとき</li>
                <li>反社会的勢力と社会的に非難されるべき関係を有しているとき</li>
              </ol>
            </li>
            <li>当社又は加盟店が、前項に定めにより加盟店契約を解除した場合、相手方に損害が生じたとしても何らこれを賠償及び補償する義務は負わないものとする。</li>
            <li>当社及び加盟店は、第２項の定めによる加盟店契約の解除により損害を被った場合、相手方に対しその損害の賠償を求めることができ、相手方はこれを賠償する義務を負うものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第２６条（契約終了後の処理)</h2>
          <ol>
            <li>契約期間の満了、第２３条、第２４条及び第２５条の規定に基づき加盟店契約が終了した場合であっても、契約終了日までに行われたJPYC取引は有効に存続するものとし、当社及び加盟店は、当該JPYC取引を加盟店契約に従い取り扱うものとする。ただし、当社及び加盟店が別途合意した場合はこの限りではない。</li>
            <li>加盟店契約が終了した場合､加盟店は直ちにJPYC取引に関連するシステムの利用を停止するものとし、当社の商標及び当社所定の加盟店マークを削除し、加盟店サイト上から当社及びJPYCに関する記述を削除するものとする。また、加盟店は､当社から加盟店契約に基づき付与された物品等を速やかに当社に返却するものとする。</li>
            <li>加盟店契約が終了した場合であっても、第２０条、第２１条、第２４条第２項乃至第４項、第２５条第３項及び第４項、本条、第２７条、第３１条、第３２条は、なお効力を有するものとする。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第２７条（損害賠償)</h2>
          <p>加盟店は、その責めに帰すべき事由により当社に損害を与えた場合、当社が被った一切の損害を賠償する責めを負うものとする。</p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第２８条（免責)</h2>
            <ol>
              <li>当社及び加盟店は、天災事変、戦争、内乱、法令の制定改廃、公権力による命令処分、労働争議、通信回線若しくは諸設備の故障、その他当社及び加盟店の責めに帰することのできない事由に起因する損害につき、互いに何らの責任も負わないものとする。</li>
              <li>前項に掲げる事由その他事由の如何を問わず、加盟店契約の履行が困難となり、若しくはそのおそれが生じ、又は加盟店契約の履行に重大な影響を及ぼす事態が生じたときは、当社及び加盟店は直ちに相手方にその旨を通知して協議を行い、双方の事業運営への影響を最小限とするよう努めるものとする。</li>
            </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第２９条（ブロックチェーン等に関する不保証)</h2>
          <p>当社は、JPYCにおいて利用するブロックチェーン及びその他のシステムに関し、加盟店が利用する時点において当社が使用又は保有している状態で提供するものであり、当社が加盟店の予定している目的、要求及び利用態様への適合性、有用性、有益性、セキュリティー、非侵害性又はエラー、バグ、論理的誤り、中断、ロールバックの不発生及びその他の不具合がないことを保証するものではなく、加盟店はこれを承諾する。</p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第３０条（譲渡禁止等)</h2>
          <p>加盟店は、加盟店契約及び加盟店契約によって当社に対して有する一切の権利及び加盟店契約又は本規約の地位を、第三者に譲渡、賃貸、質入れその他の担保設定等の処分をしてはならないものとする。</p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第３１条（準拠法)</h2>
          <p>当社及び加盟店との間の契約の準拠法は、全て日本法が適用されるものとする。</p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第３２条（合意管轄裁判所)</h2>
          <p>加盟店契約又は本規約に関し、当社及び加盟店との間で訴訟の必要が生じた場合は、東京地方裁判所を第一審の専属的合意管轄裁判所とする。</p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第３３条（本規約に定めのない事項)</h2>
          <p>加盟店契約若しくは本規約に定めのない事項又は加盟店契約の履行に関し疑義を生じた場合には､当社と加盟店との間で誠意を持って協議し、円満解決を図るものとする。</p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第３４条（本規約の変更)</h2>
          <ol>
            <li>当社は、次の各号に該当する場合には、あらかじめ、効力発生日を定め、本規約を変更することができる。この場合、当社は、変更後の内容及び効力発生時期を、相当な方法で加盟店に周知及び公表し、本規約を変更するものとする。
              <ol className={styles.termsInnerOl_2}>            
                <li>変更の内容が加盟店の一般の利益に適合するとき</li>
                <li>変更の内容が加盟店契約にかかる取引の目的に反せず、変更の必要性、変更後の内容の相当性その他の変更にかかる事情に照らし、合理的なものであるとき</li>
              </ol>
            </li>
            <li>前項に基づく変更の外、当社は、本規約の送付その他の方法により加盟店に通知した後に、本規約の内容を変更できるものとする。この場合、加盟店は、当該通知の後に本規約にかかるJPYC取引を行うことにより、承諾の意思表示を行うものとし、当該意思表示をもって本規約の変更が効力を生ずるものとする。</li>
          </ol>
        </ul>
        <UnderLine />
      </div>
      <Footer />
    </>
  )
}

export default Terms
