import jpycTopLogo from '../../../images/jpycTopLogo.svg'
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.title}>
          <a href="/">
            <img className={styles.headerLogo} src={jpycTopLogo} alt="" />
          </a>
        </div>
        <a href='https://app.jpyc.jp/' target='_blank' rel="noreferrer">
          <div className={styles.buyJpycButton}>
            <p>JPYCを購入する</p>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Header
