import React from 'react'
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import PagesHeader from '../../common/PagesHeader'
import UnderLine from '../../common/Props'
import styles from '../Terms.module.scss'

const Terms = () => {
  return (
    <>
      <Header />
      <PagesHeader title={"JPYC商品券利用規約"} />
      <div className={styles.termsWrapper}>
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第1条 (本規約の適用について)</h2>
          <ol>
            <li>
            本規約は、JPYC株式会社（以下「当社」といいます。）が紙型により発行する第三者型前払式支払手段である商品券（以下「JPYC商品券」といいます。）について定めるものです。利用者は、本規約の内容を十分に理解し、本規約に同意したうえで、JPYC商品券を利用するものとします。
            </li>
            <li>
            利用者が未成年者である場合は、法定代理人の同意を得たうえでJPYC商品券を購入し、又は利用するものとします。また、利用者がJPYC商品券を利用者の所属する法人その他の事業者のために利用する場合は、当該事業者等から利用に関する全ての権限を授与されたうえでJPYC商品券を利用するものとします。なお、この場合、当該事業者等も利用者とみなします。
            </li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第2条 (定義)</h2>
          <p>本規約において使用する語句は、それぞれ以下の意味を有するものとします。</p>
          <ol>
            <li>「本サービス」とは、JPYC商品券にかかるサービス、および付帯するサービス全般をいいます。</li>
            <li>「利用者」とは、JPYC商品券を購入しようとする者、JPYC商品券の購入者、JPYC商品券の保有者、本サービスを利用しようとする者並びに本サービスの利用者をいいます。</li>
            <li>「対象商品」とは、JPYC商品券を使用して加盟店において代金決済が可能な商品、サービス等をいいます。</li>
            <li>「加盟店」とは、利用者が対象商品を購入し、またはふるさと納税を行う際に決済手段として利用できる相手方として当社が指定する者をいいます。</li>
            <li>「当社所定」とは、当社が定める個別詳細についての事項であり、当社のWebサイト等にて掲示します。</li>
            <li>「必要措置」とは、JPYC商品券の利用の禁止又は譲渡の禁止、JPYC商品券の失効その他当社が必要かつ適切と判断する措置の全部または一部をいいます。</li>
            <li>「資金決済法」とは、「資金決済に関する法律」(平成二十一年法律第五十九号)をいいます。</li>
            <li>「犯罪収益移転防止法」とは、「犯罪による収益の移転防止に関する法律」（平成十九年法律第二十二号）をいいます。</li>
            <li>「反社会的勢力」とは、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動標ぼうゴロまたは特殊知能暴力団、その他これに準ずる者をいいます。</li>
            <li>「非居住者」とは、日本国内に住所のない者等をいいます。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第3条 (JPYC商品券の発行(購入))</h2>
          <ol>
            <li>当社は、当社が認めた利用者に対して、所定の手続きを経たうえでJPYC商品券を発行します。</li>
            <li>利用者は、JPYC商品券を購入しようとする場合、当社所定の情報を当社に対して提供した上でJPYC商品券の購入を申し込むものとします。なお、未成年の場合には、親権者等法定代理人の同意を得たうえで申込を行うものとします。</li>
            <li>JPYC商品券の発行に際しては、当社が定める利用可否判定が行われます。利用者がかかる利用可否判定を通過しなかった場合、当社はJPYC商品券を発行することができません。なお、当社は利用可否判定の不通過の理由を開示することはできません。</li>
            <li>当社は、利用者の申請した住所にJPYC商品券を送付します。なお、住所違いや利用者の不受理等で当該JPYC商品券が当社に返送された場合は、当社は6ヶ月以上の保管期間の経過後、当該JPYC商品券を廃棄できます。この場合、利用者が支払った発行手数料(郵送費を含みます)は返還されません。</li>
            <li>利用者は、JPYC商品券について、廃止等した場合を除いて、期間の制限なく利用することができます。</li>
            <li>JPYC商品券は理由の如何を問わず再発行をすることはできません。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第4条 (JPYC商品券の利用(決済))</h2>
          <ol>
            <li>利用者は、本規約に同意した場合、加盟店に対して、当社所定の方法でJPYC商品券を使用することによって、券面記載の金額でJPYC商品券をふるさと納税のための決済に利用することができます。</li>
            <li>利用者は、以下の各号に該当する場合、JPYC商品券を利用することができません。</li>
              <ol>
                <li>JPYC商品券が偽造・変造、その他不正に加工されたものであるとき</li>
                <li>利用者がJPYC商品券を違法に取得し、または、違法に取得されたJPYC商品券と知りながら若しくは知ることができた状態で取得したとき</li>
                <li>JPYC商品券の破損その他の事由によりJPYC商品券であることの判別が困難である場合</li>
                <li>地震、津波、暴風雨、洪水、戦争、暴動、内乱、反乱、革命、テロリズム、大規模火災、感染症、疫病、伝染病、ストライキ、ロックアウト、法令の制定・改廃、その他当事者の合理的支配を超えた偶発的な事象が発生した場合</li>
                <li>JPYC商品券が偽造・変造されたものでないことの確認が困難である場合その他相当の事由により、当社がJPYC商品券の取扱停止を決定した場合</li>
                <li>利用者が本規約に違反した場合、または違反するおそれがあると当社が判断した場合</li>
                <li>利用者が仮差押もしくは差押を受けた場合、または利用者に対して破産もしくは⺠事再生の申し立て等がなされた場合</li>
                <li>裁判所や警察、その他の行政機関から、利用者によるJPYC商品券の停止命令がでた場合</li>
                <li>当該JPYC商品券が不正利用された場合、またはそのおそれがある場合</li>
                <li>その他、当社が不適切であると合理的に判断した場合</li>
              </ol>
            <li>利用者は、一度決済に使用されたJPYC商品券を再度使用することはできません。</li>
            <li>当社は、利用者と加盟店との間の取引について、当事者、代理人、仲立人等にはならず、その成立、有効性、履行等に関していかなる法的責任も負いません。万一、JPYC商品券を利用した後に債務不履行、返品、契約不適合その他の問題が生じた場合であっても、当社はJPYC商品券の返還等を行う義務を負わず、その問題については利用者と加盟店との間で解決するものとします。</li>
          </ol>
          </ul> 
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第5条 (JPYC商品券の譲渡)</h2>
          <ol>
            <li>利用者は、第三者を本規約に同意させた場合、当該第三者に対して、JPYC商品券の引渡しにより、JPYC商品券を譲渡することができます。</li>
            <li>JPYC商品券の譲渡後は、当社において譲渡の取り消しは行えません(ただし、法令に定める場合を除きます。)。意図しない譲渡を誤って行ってしまった場合は、譲渡人は、譲受人との間で直接これを解決するものとします。</li>
            <li>当社は、JPYC商品券の譲渡人と譲受人との間の取引その他の法律関係について、当事者、代理人、仲立人等にはならず、その成立、有効性、履行等に関していかなる法的責任も負わないものとします。</li>
            <li>万一、JPYC商品券の譲渡後に、かかる譲渡の原因となった反対債務の不履行又は不完全、譲受人の不法行為又は違法行為、その他の問題が生じた場合であっても、当社は、法令等に基づき義務付けられる場合を除き、JPYC商品券の返還等を行う義務を負わず、譲渡人と譲受人との間で解決していただくものとします。また、かかる問題に当社が対応したことにより当社に損害が生じた場合、当該譲渡人及び譲受人は、当該損害を賠償するものとします。</li>
            <li>JPYC商品券は、第1項に定める場合を除き、第三者に対して、有償無償を問わず、譲渡することはできません。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第6条 (払戻し等)</h2>
          <ol>
            <li>利用者は、JPYC商品券について、払戻しをすることはできません。JPYC商品券の盗難、紛失又は滅失その他の事由によって、加盟店において当該JPYC商品券を利用することができなくなった場合であっても、払戻しは認められません。</li>
            <li>当社が経済情勢の変化、法令の改廃その他当社の都合によりJPYC商品券の取扱いを全面的に廃止した場合には、利用者は法令の手続に従い残高の払戻しの請求ができます。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第7条 (手数料等)</h2>
          <ol>
            <li>当社は、本サービスを提供するに際して所定の手数料等を徴収できます。</li>
            <li>本サービスの利用に伴い、税金や付帯費用が発生する場合には、利用者がこれらを負担するものとします。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第8条 (個人情報の取扱い)</h2>
          <ol>
            <li>当社は、利用者のプライバシーおよび個人情報を当社プライバシーポリシーに従って適切に取り扱います。本条とプライバシーポリシーが抵触する場合、本条が優先して適用されます。</li>
            <li>当社は、利用者から収集した情報を安全に管理するため、セキュリティに最大限の注意を払います。</li>
            <li>当社は、法令で定める場合および不正利用調査や犯罪捜査に必要な場合、利用者の登録情報、取引履歴情報、その他の必要な情報を警察機関、金融機関、及び当社が本サービスを提供するために提携している企業に対して、開示することができるものとし、利用者はあらかじめこれに同意するものとします。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第9条 (反社会的勢力の排除)</h2>
          <ol>
            <li>利用者は、自己またはその代理人もしくは媒介者（以下「関係者」といいます。）が、現在、次のいずれにも該当しないことを表明し、かつ、将来にわたっても該当しないことを確約します。</li>
              <ol>
                <li>暴力団（暴力団員による不当な行為の防止等に関する法律（平成3年法律第77号。以下「暴対法」といいます。）第2条第2号に規定する暴力団をいいます。）</li>
                <li>暴力団員（暴対法第2条第6号に規定する暴力団員をいいます。）および暴力団員でなくなった時から5年を経過しない者</li>
                <li>暴力団準構成員</li>
                <li>暴力団関係企業</li>
                <li>総会屋等、社会運動標ぼうゴロ、政治活動標ぼうゴロ、または特殊知能暴力集団</li>
                <li>前各号に定める者と密接な関わり（資金その他の便益提供行為を含みますが、これらに限りません。）を有する者</li>
                <li>その他前各号に準じる者</li>
              </ol>
            <li>利用者は、自らまたはその関係者が、直接的または間接的に、次の各号に該当する行為を行わないことを確約します。</li>
              <ol>
                <li>暴力的な要求行為</li>
                <li>法的な責任を超えた不当な要求行為</li>
                <li>取引に関して、脅迫的な言動（自己またはその関係者が前項に定める者である旨を伝えることを含みますが、これに限りません。）をし、または暴力を用いる行為</li>
                <li>風説を流布し、偽計を用いまたは威力を用いて当社の信用を毀損し、または当社の業務を妨害する行為</li>
                <li>その他前各号に準じる行為</li>
              </ol>
            <li>当社は、利用者が前二項に定める表明事項または確約事項のいずれかに違反することが判明した場合、何らの催告を要することなく必要措置を講じることができます。</li>
            <li>当社は、前項の規定により必要措置を講じた場合、かかる必要措置によって利用者に生じた損害、損失および費用を賠償する責任を負いません。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第10条 (禁止事項)</h2>
          <ol>
            <li>利用者は、以下の各号に掲げる行為を行ってはなりません。</li>
              <ol>
                <li>本規約および法令または公序良俗に違反する行為</li>
                <li>当社が指定する方法以外の方法によってJPYC商品券を利用し、又は現金、財物その他の経済上の利益と交換する行為</li>
                <li>マネー・ローンダリング目的でJPYC商品券を保有し、またはJPYC商品券をマネー・ローンダリングに利用する行為</li>
                <li>不正な方法によりJPYC商品券を取得し、または不正な方法で取得されたJPYC商品券であることを知って利用する行為</li>
                <li>JPYC商品券の購入・利用にあたって、当社に対して虚偽の情報の登録をする行為</li>
                <li>JPYC商品券を変造・偽造・複製等をする行為及び変造・偽造・複製等がされたJPYC商品券であると知りながらこれを使用する行為</li>
                <li>当社又は第三者の特許権・商標権・著作権・その他の財産的又は人格的な権利を侵害し、又は侵害するおそれのある行為</li>
                <li>当社及び権利者の名誉、人格または信用等を毀損する行為、若しくは不利益を与える行為</li>
                <li>当社の事業を妨げる行為、又は誹謗、信用等を毀損する行為</li>
                <li>犯罪行為、又は犯罪行為を誘発する、あるいは犯罪行為に結びつく恐れのある行為</li>
                <li>反社会的勢力であること、もしくは資金提供その他を通じて反社会的勢力の維持、運営、経営に協力または関与する等反社会的勢力との何らかの交流もしくは関与</li>
                <li>他の利用者、その他第三者に損害を与える行為、誹謗・中傷する行為</li>
                <li>当社に損害を与えるあるいは与える恐れのある行為</li>
                <li>当社のサーバやネットワークシステムに支障を与える行為、技術的手段を利用してサービスを不正に操作する行為、当社のシステムの不具合を意図的に利用する行為、同様の質問を必要以上に繰り返す等、当社に対し不当な問い合わせまたは要求をする行為、その他当社による事業の運営または他の利用者によるこれらの利用を妨害し、これらに支障を与える行為</li>
                <li>同一または類似の行為を繰り返す等通常の利用の範囲を超えた利用行為</li>
                <li>その他、当社が禁止する一切の行為</li>
              </ol>
            <li>当社は、利用者が本サービスの利用にあたって適用される本規約に違反しまたは違反するおそれがあると認めた場合（前項各号のいずれかに該当し、またはそのおそれがあると当社が判断する場合を含みますが、これらに限りません。）、あらかじめ利用者に通知することなく必要措置を講じることができます。</li>
            <li>前項の規定にかかわらず、当社は、他の利用者その他のいかなる第三者に対しても、利用者の違反を防止または是正する義務を負いません。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第11条 (本サービスの一時停止)</h2>
          <ol>
            <li>当社は、以下の各号のいずれかに該当する場合には、利用者に事前に通知することなく本サービスの全部または一部を一時的に停止することができます。</li>
              <ol className={styles.termsInnerOl_2}>
                <li>サーバ、通信回線、その他の設備の故障、障害の発生</li>
                <li>システム(サーバ、通信回線や電源、それらを収容する建築物などを含む)の保守、点検(定期的点検を除く)、修理</li>
                <li>戦争、暴動、ストライキ、災害、疫病の発生その他不可抗力(以下「不可抗力事由」といいます。)</li>
                <li>その他、当社が必要と判断した場合</li>
              </ol>
            <li>当社は、本サービスの一時停止により利用者に損害が生じた場合であっても、一切その責任を負わないものとします。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第12条 (本サービスの終了)</h2>
          <ol>
            <li>当社は、不可抗力事由の発生、社会情勢の変化、法令の改廃、技術革新、経営判断等により、いつでも本サービスの全部または一部を終了できるものとします。</li>
            <li>当社は、本サービスの終了につき法令に従って公告を行うものとし、公告から当社が定める期間(60日以上)において、利用者は当社所定の方法により、保有するJPYC商品券の払戻しを請求できます。</li>
            <li>当社は資金決済法に基づき、基準日未使用残高の半分の額以上の額に相当する額を資産保全しており、当社の破産手続開始決定等が行われた場合、利用者は資産保全された発行保証金について、他の債権者より優先的に払戻しによる弁済を受けることができます。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第13条 (知的財産権)</h2>
          <ol>
            <li>本サービスに関する知的財産権または権利は、当社または当該権利を有する第三者に帰属します。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第14条 (利用者の責任)</h2>
          <ol>
            <li>利用者は、利用者自身の責任において本サービスを利用するものとし、本サービスの利用において行った一切の行為およびその結果について一切の責任を負うものとします。</li>
            <li>利用者は、本サービスを利用したことに起因して（当社がかかる利用を原因とするクレームを第三者より受けた場合を含みます。）、当社が直接的もしくは間接的に何らかの損害（弁護士費用の負担を含みます。）を被った場合、当社の請求にしたがって直ちにこれを賠償する責任を負うものとします。</li>
            <li>
              当社は、以下の何れかの事由に該当する場合、お客様に事前に通知することなく本サービスの一部もしくは全部を中断又は停止することがあります。
              <ol className={styles.termsInnerOl_2}>
                <li>各サービスの提供のための装置、システムの保守点検、更新を定期的に又は緊急に行う場合</li>
                <li>火災、停電、天災等不可抗力により、各サービスの提供が困難な場合</li>
                <li>本サービスを提供するための通信又は回線の障害が生じる等、運用上又は技術上、本サービスの提供が不能又は困難になった場合</li>
                <li>その他、運用上あるいは技術上当社が各サービスの一時中断、もしくは、停止が必要であるか、又は当社が各サービスの提供が困難と判断した場合</li>
              </ol>
            </li>
            <li>当社は、各サービスの提供の一時中断、停止等の発生により、お客様又は自治体が被った不利益、損害について、責任を負いません。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第15条 (免責)</h2>
          <ol>
            <li>当社が本規約に従って行うJPYC商品券の利用の停止、本サービスの停止又は終了、本規約の変更、JPYC商品券の盗難、紛失又は滅失、その他本サービスに起因又は関連して利用者が損害を被った場合、当社に故意又は過失がある場合を除き、当社は賠償する責任を負いません。</li>
            <li>当社の債務不履行又は不法行為により本サービスに関連して利用者に損害が生じた場合、当社に行為又は重過失がある場合を除き、当社は通常生ずべき損害（逸失利益その他特別の事情によって生じた損害を含みません。）の範囲内で、責任を負うものとします。なお、当社の過失（重過失を除きます。）による債務不履行または不法行為により利用者に生じた損害の賠償は、利用者が購入したJPYC商品券の購入額を上限とします。</li>
            <li>当社は、補償の提供の遅延、変更、中断、停止もしくは終了、その他補償に関連してまたは補償を受けられないことにより､JPYC商品券の保有者が不利益を被ったとしても、当社の故意または重過失に起因する場合を除き、一切の責任を負いません。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第16条 (不正利用に基づく補償)</h2>
            <li>当社は、利用者が意図せず、第三者によりJPYC商品券を不正利用されたことにより損害を被った場合、利用者に生じた損失について、原則として、その責任を負わないものとします。ただし、不正利用の有無及び原因、利用者の利用規約違反や故意過失の有無等を調査した結果、利用者の保護が特に必要である場合には、その損失を補償する等の対応を個別に検討します。</li>
            <li>本条における損害は、前項に定める事象によって、利用者の意図に反した不正な入金、決済、譲渡が行われた時点をもって、発生したとみなします。</li>
            <li>前二項にかかわらず、不正利用の補償の対象は、損失が発生した日から30日以内に、利用者が当社および警察署に申告したものに限ります。また、補償を受ける利用者に対して当社所定の本人確認手続を受ける必要があります。</li>
            <li>以下の場合、不正利用の補償の対象となりません。</li>
              <ol>
                <li>JPYC商品券の紛失・盗難、または紛失・盗難によって発生した不正利用による損害</li>
                <li>利用者の故意もしくは重大な過失、または法令違反に起因する不正利用</li>
                <li>利用者が行った不正利用(第三者に強要されて行った不正利用を含む)</li>
                <li>利用者の家族、近親者、同居人、利用者の依頼(家族、近親者等による依頼を含む)を受けて介護、世話等をする者、利用者の承諾等を得て本サービスを利用する者が行った不正利用</li>
                <li>利用者が本規約、その他当社の定めに違反している場合</li>
                <li>利用者からの不正利用の申告が、虚偽である場合、またはその疑いがある場合</li>
                <li>利用者が不正利用に加担している場合、またはその疑いがある場合</li>
                <li>利用者が過去1年以内の別の補償申請に基づいて、補償を受けていた場合</li>
                <li>利用者が不正利用の調査について協力をしない場合</li>
                <li>利用者が不正利用による損害の拡大を防ぐための行為をしない場合</li>
                <li>不正利用の結果として実際に利用者に生じた、金銭的損害以外の損害額</li>
                <li>戦争、災害、疫病、地震等、社会的混乱の際に生じた不正利用</li>
                <li>その他当社が不適当と合理的に判断する場合</li>
              </ol>
            <li>当社の補償内容は以下のとおりとなります。</li>
              <ol>
                <li>当社は、第1項に基づき補償を行う場合、利用者が第三者に不正利用された金額から、当社以外の第三者から回収できた金額または補償を受けられた金額を差し引いた金額を補償します。</li>
                <li>当社は、補償する額に相当するJPYC商品券を、利用者に交付する方法により、本条の補償を行うことができます。ただし、現金による補償が適切であると当社が認める場合は現金による補償を行う場合があります。なお、補償に際して生じる手数料は当社の負担とします。</li>
              </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第17条 (規約の変更)</h2>
          <ol>
            <li>当社は以下の場合に、当社の裁量により、本規約を変更することができます。</li>
              <ol>
                <li>本規約の変更が、利用者の一般の利益に適合するとき</li>
                <li>本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき</li>
              </ol>
            <li>当社は前項による本規約の変更にあたり、本規約を変更する旨、変更後の本規約の内容及び効力発生日を、当社が適切であると判断する方法により周知します。</li>
            <li>本規約の変更の効力発生日以降に利用者がJPYC商品券を利用したときは、利用者は、本規約の変更に同意したものとみなします。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第18条 (譲渡等)</h2>
          <ol>
            <li>利用者は、第三者に対し、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利もしくは義務について、譲渡、移転、担保設定、その他の処分をすることはできません。</li>
            <li>当社はJPYC商品券に関する事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに利用者の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第19条 (分離可能性)</h2>
          <ol>
            <li>本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</li>
              </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>第20条 (準拠法・合意管轄)</h2>
          <ol>
            <li>本規約の準拠法は日本法とします。</li>
            <li>本規約又はJPYC商品券の利用に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
          </ol>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2>第21条 (お問い合わせ先)</h2>
            <ol>
              <li>JPYC商品券の発行及び利用に関する相談は、下記にご連絡ください。</li>
            </ol>
            <p>＜発行元＞</p>
            <p>JPYC株式会社</p>
            <p>〒100-0004 東京都千代田区大手町一丁目6番1号 大手町ビル4階 FINOLAB内</p>
            <p>お問い合わせフォーム：https://jpyc.co.jp/contact</p>
            <p>電話番号：03-6820-0461</p>
        </ul>
        <UnderLine />
        <ul className={styles.termsBox}>
          <h2 className={styles.clauseTitle}>附則</h2>
          <p>2023年11月13日制定</p>
        </ul>
        
      </div>
      <Footer />
    </>
  )
}

export default Terms
