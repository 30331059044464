import styles from "../Tokushoho.module.scss";

const Gift = () => {
  return (
    <div>
      <h2 className={styles.tokushohoTitle}>JPYCギフト交換</h2>
      <ul className={styles.tokushohoFrame}>
        <li>
          <div className={`${styles.leftBox} ${styles.borderTL}`}>
            <h3>販売業者名</h3>
          </div>
          <div className={`${styles.rightBox} ${styles.borderTR}`}>
            <p>JPYC株式会社</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>責任者</h3>
          </div>
          <div className={styles.rightBox}>
            <p>代表取締役 岡部典孝</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>所在地</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              〒100-0004 東京都千代田区大手町一丁目6番1号 大手町ビル4階
              FINOLAB内
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>メールアドレス</h3>
          </div>
          <div className={styles.rightBox}>
            <p>jpyc_support@jcam.co.jp</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>お問い合せ</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              <a
                href="https://docs.jpyc.jp/"
                target="_blank"
                rel="noopener"
              >
                個人の方
              </a>
              <br />
              <a
                href="https://jpyc.co.jp/contact"
                target="_blank"
                rel="noopener"
              >
                法人の方
              </a>
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>ウェブサイトURL</h3>
          </div>
          <div className={styles.rightBox}>
            <p>https://app.jpyc.jp/gift/</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>交換金額</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              （Vプリカギフト交換）
              <br />
              交換券種：1万円分 、5万円分、10万円分
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>代金以外の必要料金</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              JPYCを弊社に送信する際のネットワーク利用手数料（いわゆるGAS代）
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>お支払い方法</h3>
          </div>
          <div className={styles.rightBox}>
            <p>JPYCによる決済</p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>
              支払時期
              <br />
              申込みの有効期限
            </h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              弊社より注文確認メール到着後、1週間以内にJPYCにてお支払ください。
              <br />
              お申込日から1週間以内にJPYCの受信を確認できなかった場合はキャンセルされたものとみなし、
              <br />
              申込みは取り消させていただきます。
            </p>
          </div>
        </li>
        <li>
          <div className={styles.leftBox}>
            <h3>引き渡し期間</h3>
          </div>
          <div className={styles.rightBox}>
            <p>
              （Vプリカギフト）
              <br />
              原則として、お客様のご入金を確認後、翌営業日まで。ただし、在庫状況等により、お客様のご入金確認後から最大5営業日ほど要する場合があります。
            </p>
          </div>
        </li>
        <li>
          <div className={`${styles.leftBox} ${styles.borderBL}`}>
            <h3>返品・交換・キャンセル</h3>
          </div>
          <div className={`${styles.rightBox} ${styles.borderBR}`}>
            <p>
              購入・手続き完了後の返品・交換・キャンセルはお受けできません。
            </p>
          </div>
        </li>
      </ul>
      <p className={`${styles.tokushoNotice}`}>
        ※連絡先電話番号については、お問い合わせフォームよりご請求いただければ、遅滞なく開示いたします。
      </p>
    </div>
  );
};

export default Gift;
