import React from "react";
import styles from "./Boxes.module.scss";
import iconGroup from "../../../images/iconGroup01.png";
import prepaidImg from "../../../images/prepaid.svg";
import giftImg from "../../../images/creditcard.png";

const BoxesSection = () => {
  return (
    <section className={styles.boxesWrapper}>
      <div className={styles.stableCoinBox}>
        <div className={styles.stableCoinBoxWrapper}>
          <img className={styles.iconGroup} src={iconGroup} alt="icon-group" />
          <h3 className={styles.stableTitle}>
            パブリックブロックチェーン上で作られたプリペイド型のステーブルコイン
          </h3>
          <p className={styles.stableSubText}>
            Ethereum、Polygon、Gnosis、Shiden、Avalanche、Astarで発行しており、JPYC
            Appsで1JPYC=1円として利用ができます。JPYCはERC20規格のトークンのため、メタマスクなどのウォレットで個人で管理する必要があります。
          </p>
        </div>
      </div>
      <div className={styles.rightBox}>
        <div className={styles.prepaidBox}>
          <div className={styles.prepaidImgWrapper}>
            <img
              className={styles.prepaidImg}
              src={prepaidImg}
              alt="prepaid-img"
            />
          </div>
          <div className={styles.prepaidTextWrapper}>
            <h3 className={styles.prepaidTitle}>
              暗号資産ではなく、前払式支払手段（プリペイド）
            </h3>
            <p className={styles.prepaidSubText}>
              常に会計上1JPYC=1円で計算できる前払式支払手段であるため、暗号資産と比べて事業でのブロックチェーン活用に最適です。
            </p>
          </div>
        </div>
        <div className={styles.giftBox}>
          <div className={styles.giftBoxWrapper}>
            <img className={styles.giftImg} src={giftImg} alt="gift-img" />
            <div className={styles.giftTextWrapper}>
              <h3 className={styles.giftTitle}>
                JPYCをVプリカギフトなどと交換して日常のお買物に使える
              </h3>
              <p className={styles.giftSubText}>
                JPYCは、インターネット上のVisa加盟店なら世界中どこでもクレジットカードと同じように使えるＶプリカギフトなどと交換して日常のお買物に使うことができます。
                <br />
                ※3Dセキュアのご利用はVプリカアカウントへの登録が必要です。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoxesSection;
